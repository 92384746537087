import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
  down_arrow,
  image1,
  logout_image,
  notification,
  profile_image,
  Regenerate_course_image,
} from "../../../../assets/images/images";
import "../../styles/dashboard.css";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../api/api";
import imgs from "../../../../assets/images/imgs";
import StyledMenu from "../../../../components/StyleMenu";
import AdminUniversalSearch from "../../../../components/UniversalSearch/AdminUniversalSearch";
import UniversalSearch from "../../../../components/UniversalSearch/UniversalSearch";
import { useAuth } from "../../../../features/auth/AuthContext";
import { getProfileDetails } from "../../../../state/actions/getProfileDetailsAction";
import { getDataList } from "../../../../state/actions/getdataListAction";
import {
  getGoogleTokenAction,
  resetGoogleToken,
} from "../../../../state/actions/getGoogleTokenAction";
import { updateGlobalState } from "../../../../state/global/slice";
import {
  setSyncSchoologydtls,
  resetSyncSchoology,
} from "../../../../state/actions/syncSchoologyAction";
import { toast } from "react-toastify";
import { syncToGoogleDrive, resetgoogleDriveSync } from "../../../../state/actions/googleDriveSyncAction";

const Dashboardheader = (props) => {
  const location = useLocation();
  const hideHeaderRoutes = [
    "/login",
    "/signup",
    "/",
    "/about",
    "/contact",
    "/features",
    "/admin",
    "/admin-signup",
    "/terms-and-conditions",
    "/privacy-policy",
  ]; // Add routes where you want to hide the header
  const shouldHideHeader = (pathname) => {
    // Check if the pathname is exactly one of the predefined routes
    if (hideHeaderRoutes.includes(pathname)) return true;
    return false;
  };
  const showHeader = !shouldHideHeader(location.pathname);
  const globalState = useSelector((state) => state.globalState);
  const datalist = useSelector((state) => state.getdatalist);
  const { profileDetails } = useSelector((state) => state.profileDetails);
  const googledriveSync = useSelector((state) => state.googleDriveSync);
  const [showDrive, setshowDrive] = useState("");
  const [callingApi, setCallingApi] = useState(true);
  const { message, isLoading, error } = useSelector(
    (state) => state.syncSchoology
  );
  const navigate = useNavigate();
  const { logout, token } = useAuth();
  const [getlastupdatedschoology, setGetlastupdatedschoology] = useState();
  const dispatch = useDispatch();
  const subadmin_details = globalState.subadmin_details;
  const profileFirstName =
    location.state?.role === "Student" || globalState?.role === "Student"
      ? location.state?.student_details?.data?.name ??
      globalState?.student_details?.data?.name ??
      location.state?.role ??
      globalState?.role
      : location.state?.role === "Teacher" || globalState?.role === "Teacher"
        ? location.state?.teacher_details?.data?.name ??
        globalState?.teacher_details?.data?.name
        : location.state?.role ?? globalState?.role;

  const profileimage =
    location.state?.role === "Student" || globalState?.role === "Student"
      ? location.state?.student_details?.data?.ai_avatar ??
      globalState?.student_details?.data?.ai_avatar ??
      location.state?.role ??
      globalState?.role
      : location.state?.role === "Teacher" || globalState?.role === "Teacher"
        ? location.state?.teacher_details?.data?.ai_avatar ??
        globalState?.teacher_details?.data?.ai_avatar
        : location.state?.role ?? globalState?.role;

  const profileNameInitial = (name) => {
    return String(name)
      ?.split(" ")
      .map((n) => n[0])
      .join("");
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const onsyncSchoology = () => {
  //   dispatch(setSyncSchoologydtls(token));
  // };
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  useEffect(() => {
    if (globalState.role === "Student" && token !== null) {
      dispatch(getProfileDetails(token));
    }
  }, [globalState.role, token]);

  useEffect(() => {
    if (profileDetails !== null) {
      updateState("student_details", profileDetails ?? {});
    }
  }, [profileDetails]);
  useEffect(() => {
    api
      .get("/courses/get-grade-list/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //return 1;
          if (response?.data) {
            updateState("grade_details", response?.data ?? {});
          }
          // localStorage.setItem('grade_details', JSON.stringify(response?.data ?? ''));
        }
      })
      .catch((error) => { });
  }, [token]);
  function handleLogout() {
    // Call the logout function here
    logout();

    if (globalState.role === "SuperAdmin") {
      navigate("/superadmin-login", { replace: true });
    } else if (globalState.role === "Admin") {
      navigate("/admin", { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  }
  useEffect(() => {
    if (globalState?.notifications?.length > 0) {
      setNotifications((prevNotifications) => [
        ...globalState.notifications,
        ...(Array.isArray(prevNotifications) ? prevNotifications : []),
      ]);
    }
  }, [props.notifications]);
  const [notifications, setNotifications] = useState(
    globalState.my_notifications
  );


  const [showNotification, setShowNotification] = useState(false);
  const OnNotification = () => {
    setShowNotification(!showNotification);
  };
  const closeNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (datalist) {
      updateState("datalist", datalist.dataList ?? {});
    }
  }, [datalist]);
  const getNotificationList = () => {
    navigate("/notification-page");
  };

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {

      setCallingApi(false)
      try {
        const response = await api.get(`notifications/notifications_list/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });
        if (response.status === 200 || response.status === 201) {
          setNotifications(response.data);
          updateState("my_notifications", response.data);
        } else {
          console.error("Fetch error:", response);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if ((globalState?.teacher_details || globalState?.student_details) && callingApi) {
      fetchData()
    }

  }, [token, globalState?.teacher_details, globalState?.student_details]);

  useEffect(() => {
    if (token) {
      dispatch(getDataList(token));
      if (globalState?.role !== "Student") {
        dispatch(getGoogleTokenAction(token));
      }
    }
  }, [token]);

  useEffect(() => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";

    if (message) {
      toast.success(
        "Data synchronization with Schoology is in progress. You will be notified when the process is complete.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      api
        .get("/auth/get-teacher/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response?.data) {
              setGetlastupdatedschoology(
                response?.data?.data?.last_schoology_sync
              );

              updateState("teacher_details", response?.data ?? {});
            }
          }
        })
        .catch((error) => { });
    } else if (error) {
      toast.error(error || failedErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetSyncSchoology());
    }
    return () => {
      dispatch(resetSyncSchoology());
    };
  }, [message, error]);

  const syncToDrive = () => {
    dispatch(syncToGoogleDrive(token));
  };

  useEffect(() => {
    if (googledriveSync?.message) {
      toast.success("Uploading is in progress; We will notify you once it is completed.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (googledriveSync?.error) {
      toast.error("Please connect to your Google Drive", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    return () => {
      dispatch(resetgoogleDriveSync());
    }
  }, [googledriveSync])

  useEffect(() => {
    setshowDrive(location.pathname.split("/")[location.pathname.split("/").length - 1])
  }, [location.pathname])

  return showHeader ? (
    <Grid
      id="dashboardHeader-container"
      sx={{
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
        margin: "auto",
        backgroundColor: "white",
        zIndex: "113",
      }}
    >
      <div className="dashboardHeader">
        <div className="dashboardSearch">
          <h2 className="dashboardHeaderText">
            <NavLink to="/">
              <div className="logo">
                <img src={image1} alt="logo" />
              </div>
            </NavLink>
          </h2>
          {location.state?.role === "Admin" || globalState?.role === "Admin" ? (
            <AdminUniversalSearch />
          ) : (
            <UniversalSearch />
          )}
        </div>
        <div className="dashboardHeaderProfile"> </div>
        <div className="dashboardHeaderProfile" style={{ cursor: "unset" }}>

          {/* {(showDrive !== "NewLessons" && showDrive !== "view") && (location.state?.role === "Teacher" ||
            globalState?.role === "Teacher") && (
            <> */}
          {/* <button className="Sync-Schoology-drive-btn" onClick={!googledriveSync?.isLoading ? syncToDrive : () => { }} disabled={googledriveSync?.isLoading}>
                Upload to Drive
              </button> */}
          {/* {getlastupdatedschoology && <p>Last synced on {getlastupdatedschoology}</p>} */}
          {/* {getlastupdatedschoology ? (
                <p>
                  Last synced on{" "}
                  {new Date(getlastupdatedschoology).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </p>
              ) : (
                <p>
                  {globalState?.teacher_details?.data?.last_schoology_sync ? (
                    <p>
                      Last synced on{" "}
                      {new Date(
                        globalState?.teacher_details?.data?.last_schoology_sync
                      ).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </p>
                  ) : (
                    "Not synced with Schoology"
                  )}
                </p>
              )}
              {isLoading && (
                <img
                    className={`loading-sync-schoology ${isLoading ? "spinning" : ""
                      }`}
                    src={Regenerate_course_image}
                    alt=""
                  />
                )}
                <button className="Sync-Schoology-btn" onClick={onsyncSchoology}>
                  Sync With Schoology
                </button> */}
          {/* </>
            )} */}
          {location.state?.role === "Student" ||
            globalState?.role === "Student" ||
            location.state?.role === "Teacher" ||
            globalState?.role === "Teacher" ? (
            <div style={{ position: "relative" }} onClick={OnNotification}>
              <ReactSVG src={notification} />
              {notifications?.length > 0 && (
                <span
                  style={{
                    position: "absolute",
                    bottom: 10,
                    left: 10,
                    height: "25px",
                    width: "25px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    border: "1px solid white",
                    color: "#ffffff",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "11px",
                  }}
                >
                  {notifications?.length}
                </span>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="profile-wrapper" onClick={handleClick}>
            <p className="dashboardHeaderProfileNameInitial">
              {profileNameInitial(profileFirstName)}
            </p>
            <p className="dashboardHeaderProfileText">{profileFirstName}</p>
            <ReactSVG
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              src={down_arrow}
            />
          </div>
          <StyledMenu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                minWidth: 180,
                elevation: 0,
                sx: {
                  overflow: "visible",
                  // filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    w0idth: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 4,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {(location.state?.role === "Student" ||
              globalState?.role === "Student" ||
              location.state?.role === "Teacher" ||
              globalState?.role === "Teacher") && (
                <MenuItem
                  onClick={() => {
                    navigate("/settings");
                    handleClose();
                  }}
                  disableRipple
                >
                  <button type="button" className="profile-menu-header">
                    <ReactSVG src={profile_image} />
                    <p>Profile</p>
                  </button>
                </MenuItem>
              )}
            <MenuItem onClick={handleLogout} disableRipple>
              <button type="button" className="profile-menu-header">
                <ReactSVG src={logout_image} />
                <p>Logout</p>
              </button>
            </MenuItem>
          </StyledMenu>
          {showNotification && (
            <div
              onMouseLeave={closeNotification}
              className="notifications-wrapper"
            >
              <div className="notifications-container">
                <div className="notifications-title">
                  <ReactSVG src={notification} />
                  <p>Notifications</p>
                </div>
                <div
                  className="notifications-all-container-wrapper"
                  style={{
                    alignItems: "flex-start",
                    height:
                      notifications?.length > 0
                        ? notifications?.length * 110
                        : "100px",
                  }}
                >
                  <div className="notifications-all-container">
                    {notifications?.length > 0 ? (
                      notifications?.map((notification, index) => (
                        <NotificationsCard
                          profileimage={profileimage}
                          notification={notification}
                          notifications={notifications}
                          setNotifications={setNotifications}
                          key={index}
                          closeNotification={closeNotification}
                        />
                      ))
                    ) : (
                      <>There are no notifications</>
                    )}
                  </div>
                </div>
                <div
                  className="notification-view-all-btn"
                  onClick={getNotificationList}
                >
                  View All
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
    </Grid>
  ) : (
    <></>
  );
};
const NotificationsCard = ({
  notification,
  notifications,
  key,
  setNotifications,
  closeNotification,
  profileimage
}) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const isTeacher = globalState.role === "Teacher";
  const id = notification.id;
  const readNotification = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      const payload = { notification_id: id };
      const response = await api.post(
        `notifications/notifications_list/`,
        payload,
        { headers }
      );
      if (response.status === 200 || response.status === 201) {
        // remove notification from the list
        setNotifications(notifications.filter((item) => item.id !== id));
        closeNotification();
        if (notification.course_id) {
          navigate(
            isTeacher
              ? `course/${notification.course_id}/view`
              : notification.is_accepted
                ? `course/${notification.course_id}/`
                : `course/${notification.course_id}/`,
            {
              state: { course_id: notification.course_id },
            }
          );
        } else {
          // If no course_id is present, reload the current page
          window.location.href = "/dashboard/home"; // or use window.location.reload() if you want to reload the current page
        }
      } else {
        console.error("Fetch error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="notifications-folder"
      key={key}
      onClick={readNotification}
      disabled={!id}
    >
      <div className="notifications-inner-folder">
        <div className="notifications-image">
          <img
            src={
              !notification.profile_pic
                ? (profileimage ? profileimage : imgs.png.student_list_logo)
                : notification?.profile_pic
            }
            alt=""
          />
        </div>
        <p>{notification?.notification_message}</p>
      </div>
      <div className="notification-time-folder">
        <span>{notification?.time}</span>
        <span>{notification?.datetime}</span>
      </div>
    </div>
  );
};
export default Dashboardheader;