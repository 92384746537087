import { Dispatch } from "redux";
import api from "../../api/api";
import { setSchoologyRecentSync, setloading, seterror, resetSchoologyRecentSync } from "../reducers/schoologyRecentSyncReducer";

const schoologyRecentSync = (token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .get(
                `/courses/schoology-recent-logs/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            );



        if (result) {
            dispatch(setloading(false));
            if (result?.status === 200) {
                dispatch(setSchoologyRecentSync({
                    logs: result.data.logs,
                    status: result?.status,
                    pending: result.data.pending
                }));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }


    }
    catch (error: any) {

        dispatch(seterror(error.response.data.message ?? "Something Went Wrong!"));
    }
}


export { schoologyRecentSync, resetSchoologyRecentSync };

