import { Dispatch } from "redux";
import api from "../../api/api";
import { setSyncWithSrds, setloading, seterror, resetSyncSchoology } from "../reducers/syncWithSrdsReducer";

const syncWithSrds = (token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .get(
                `/courses/retrieve-schoology-courses/?fetch=true&bypass=true`,
                // `/courses/retrieve-schoology-courses/?fetch=true`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            );



        if (result)
            if (result) {

                dispatch(setloading(false));
                if (result.data?.status === 200) {
                    dispatch(setSyncWithSrds({
                        sections: result.data.sections,
                        status: result.data.status,
                    }));
                } else if (result.data?.status === 207) {
                    dispatch(setSyncWithSrds({
                        sections: result.data.sections,
                        status: result.data.status,
                    }))
                    dispatch(seterror(result.data?.message));
                } else {
                    dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
                }
            }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message ?? "Something Went Wrong!"));
    }
}


export { syncWithSrds, resetSyncSchoology };

