import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Pagination from "@mui/material/Pagination";
import { CircularProgress, Grid } from "@mui/material";
import {
  massege,
  search_icon,
  student_grade_flage,
  srot,
} from "../assets/images/images";
import { useCourseContext } from "../context/CourseContext";
import Helper from "../utils/Helper";
import { Button } from "@mui/material";
import api from "../api/api";
import { useAuth } from "../features/auth/AuthContext";
import SortPopup from "../pages/customSort/SortPopup";
import FilterPopup from "../pages/customFilter/FilterPopup";
import BackButton from "./BackButton/BackButton";
import LoadingComponent from "./LoadingComponent/LoadingComponent";
import {
  convertSecondsToMinutes,
  timeDifferenceInMinutes,
  formatTime,
  addFormattedTime,
} from "../utils/utlis";
import QuestionNew from "../pages/home/components/questions/Question_new";

const AssignmentViewProgress = ({ selectedAssignment }) => {
  const { loading, error, errormsg, filter_data } = useCourseContext(
    selectedAssignment
  );

  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [TempglobalState, setTempglobalState] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [search, setSearch] = useState("");
  const [analyticHeadData, setAnalyticHeadData] = useState([]);
  const [headerData, setheaderData] = useState({});
  const [showDropdown, setShowDropdown] = useState(false); // State to control visibility
  const [analyticDetailsData, setAnalyticDetailsData] = useState({});
  let { course_id, analytic_id } = useParams();
  const student_id = location.state?.student?.student_id ?? analytic_id;
  // const assignmentId = location.state?.student?.assignment_id ?? assignment_id;
  const [isLoading, setisLoading] = useState(false);
  const [totalTimeTaken, settotalTimeTaken] = useState("");
  const [maxTimeTakenMinutes, setmaxTimeTakenMinutes] = useState(0);
  const [maxTimeTakenSeconds, setmaxTimeTakenSeconds] = useState(0);
  const fillterstatus = [
    // { id: "Pending", name: "Pending" },
    { id: "Completed", name: "Completed" },
    { id: "In-progress", name: "In progress" },
    // { id: "Overdue", name: "Overdue" },
  ];

  // console.log("student_id",student_id);
  const fillterScore = [
    // { id: 1, name: "A+" },
    // { id: 2, name: "A" },
    // { id: 3, name: "A-" },
    // { id: 4, name: "B+" },
    // { id: 5, name: "B" },
    // { id: 6, name: "B-" },
    // { id: 7, name: "C+" },
    // { id: 8, name: "C" },
    // { id: 9, name: "C-" },
    // { id: 10, name: "D+" },
    // { id: 11, name: "D" },
    // { id: 12, name: "D-" },
    // { id: 13, name: "F" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const inial_value = (currentPage - 1) * itemsPerPage;
  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblefillter, setIsVisiblefillter] = useState(false);
  const [sortValue, setSortValue] = useState(null);
  const [assignmentStatus, setAssignmentStatus] = useState("");
  const [score, setScore] = useState("");
  const [student_assignment_details, setStudent_assignment_details] = useState(
    []
  );
  const getAssignmentList = async (assignment_id) => {
    await api
      .get(`assignments/get-assignment/${assignment_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        // if (response.data.status === 200) {
        // }
      })
      .catch((error) => { });
  };
  useEffect(() => {
    if (selectedAssignment.id !== null) {
      AOS.init({ duration: 500 });

      hedleKeyupChnage(currentPage, sortValue);
    }
  }, [currentPage, TempglobalState, search, selectedAssignment]);
  // useEffect(() => {
  //   if (selectedAssignment.id !== null) {
  //     // getAssignmentList(selectedAssignmentId)
  //     console.warn(4);
  //     hedleKeyupChnage(currentPage);
  //   }
  // }, [selectedAssignment]);

  const [sortOption, setSortOption] = useState("Newest");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearch(value);
  };
  const handleAssignmentStatusChange = (event) => {
    setAssignmentStatus(event.target.value);
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  // const [loading, setLoading] = useState(false); // Add this state to your component
  const changeSubjectFillter = (event) => {
    const selectedSubject = event.target.value;
    setTempglobalState(selectedSubject);
  };
  const hedleKeyupChnage = async (page, sort) => {

    try {
      // loading = true; // Set loading to true before the request
      const pageNumber = typeof page === "number" ? page : 1;
      const response = await api.get(`assignments/assignmentresultsearch/`, {
        params: {
          page: pageNumber,
          // assignment_id: location?.state?.assignment?.assignment_id,
          assignment_id: selectedAssignment.id,
          student__name: search,
          grade: score,
          result: assignmentStatus,
          sorting: sort ? sort : 'Newest',
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });

      if (response.status == 200) {
        console.log("response", response.data.data);
        setStudent_assignment_details(response.data.data);
        setTotalPages(
          response.data.count >= 5
            ? Math.ceil(response.data.count / itemsPerPage)
            : Math.floor(response.data.count / itemsPerPage)
        ); // Calculate total pages
        setNextUrl(response.data.next); // Update next URL
        setPrevUrl(response.data.previous); // Update previous URL
        setIsVisible(false);
        setIsVisiblefillter(false);
        console.log("student_assignment_details", student_assignment_details);
      }
    } catch (error) {
      // console.error("Fetching error:", error);
    } finally {
      // loading = false;// Set loading to false after the request is completed or if an error occurs
    }
  };

  const [showdownlodefile, setShowdownlodefile] = useState(false); // State to control visibility
  const [fileNameError, setFileNameError] = useState("");
  const [fileNameDownload, setFileNameDownload] = useState("");
  const toggledownlodefile = () => {
    setFileNameDownload("");
    setFileNameError("");
    setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
  };

  const downloadFile = async () => {
    try {
      const response = await api.get(
        `assignments/excel-download-progress/` + selectedAssignment.id + `/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          responseType: "blob", // This instructs Axios to handle the response as a Blob
        }
      );
      return response.data; // This should already be a Blob
    } catch (error) {
      console.error("Download error:", error);
      throw error;
    }
  };
  const handleFileNameChange = (e) => {
    const enteredValue = e.target.value.trim();
    setFileNameDownload(enteredValue);
    if (!enteredValue) {
      setFileNameError("Please enter file name");
    } else {
      setFileNameError("");
    }
  };
  const triggerDownload = (blob, fileNameDownload) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileNameDownload + ".xlsx"; // Set the default file name for the download
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const download_as_excel = async () => {
    if (fileNameDownload === null || fileNameDownload === "") {
      setFileNameError("Please enter file name");
    } else {
      try {
        const fileBlob = await downloadFile();
        triggerDownload(fileBlob, fileNameDownload);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };
  useEffect(() => {
    if (selectedAssignment.id !== null) {
      AOS.init({
        duration: 500,
      });

      hedleKeyupChnage(currentPage, sortValue);
    }
  }, [currentPage, TempglobalState, search, selectedAssignment]);

  // useEffect(() => {
  //   if (selectedAssignment.id !== null) {
  //     // getAssignmentList(selectedAssignmentId)
  //     console.warn(4);
  //     hedleKeyupChnage(currentPage);
  //   }
  // }, [selectedAssignment]);
  // Function to handle page change
  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleApplyClick = () => {
    setCurrentPage(1)
    hedleKeyupChnage(1,sortValue);
    // onApplySort(sortOption);
    // setIsVisible(false);
  };
  const handleSortClick = (sortValue) => {
    setCurrentPage(1)
    setSortValue(sortValue)
    hedleKeyupChnage(1, sortValue);
  };

  // filterDropdownRef start
  const filterDropdownRef = useRef(null);
  useEffect(() => {
    const handleFilterClick = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setIsVisiblefillter(false);
      }
    };

    document.addEventListener("click", handleFilterClick);

    return () => {
      document.removeEventListener("click", handleFilterClick);
    };
  }, []);
  // filterDropdownRef end

  // sortDropdownRef start
  const sortDropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sortDropdownRef.current &&
        !sortDropdownRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // sortDropdownRef end

  const getcompletedTimer = (submission_data, start_time) => {
    const timeTakenArray = submission_data[0]
      ? submission_data[0]?.map((question) => question.time_taken)
      : "";
    const lasttimeinsec = timeTakenArray[timeTakenArray?.length - 1];

    return addFormattedTime(start_time, formatTime(lasttimeinsec));
  };

  const [errorData, setErrorData] = useState({
    error: false,
    errormsg: "",
  });

  const [
    assignmentcompletedDuration,
    setAssignmentcompletedDuration,
  ] = useState("");

  const toggleDropdown = async (index = 0, array = 0, student_id) => {
    await getAssignmentAnalytics(student_id); // Wait until analytics are fetched
    setAssignmentIndex(index);
    setArrayIndex(array);
    setShowDropdown(true); // Trigger the dropdown display only after fetching
  };

  const toggleDropdownclose = () => {
    setShowDropdown(false);
  };
  const getAssignmentAnalytics = async (student_id) => {
    setisLoading(true);
    try {
      const response = await api.get(
        `/assignments/submissions/?student_id=${student_id}&assignment_id=${selectedAssignment?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setAnalyticDetailsData(response.data?.data);
        setAnalyticHeadData(response.data?.data?.assignment_analytics);
        const completedAssignment = response.data?.data?.assignment_analytics.find(
          (item) => item.status === "Completed"
        );
        if (completedAssignment) {
          setheaderData(completedAssignment);
        }
        setShowDropdown(true); // Show dropdown after data is fetched
      } else {
        console.error("Fetching error:", response);
        setErrorData({ error: true, errormsg: "No Record Found" });
      }
    } catch (error) {
      console.error("Fetching error:", error);
      setErrorData({ error: true, errormsg: error.message });
    } finally {
      setisLoading(false);
    }
  };

  const [assignmentIndex, setAssignmentIndex] = useState(0);
  const [arrayIndex, setArrayIndex] = useState(0);

  return (
    <>
      <div id="AssignmentViewProgress-section">
        <div className="view-course-Details-wrapper">
          <div className="LessonViewProgress-header-section">
            <div className="view-course-Details-back-btn-folder LessonViewProgress-back-btn-folder">
              <BackButton
                buttonName={`Current Assignment : ${location?.state?.assignment?.name}`}
              />
              {/* <BackButton buttonName={`Current Assignment : ${selectedAssignment.name}`} /> */}
              {/* <span style={{ fontWeight: "600", width: "100%", textAlign: "center" }}>Current Assignment : {location?.state?.assignment?.name}</span> */}
              {/* <BackButton buttonName={`Current Assignment : ${location?.state?.assignment?.name}`} /> */}
              {/* <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left"></i> */}
              {/* <h2> Assignment<span>01</span> : Neuroscience Quiz
              </div>Current Assignment : {location?.state?.assignment?.name} */}
              {/* </h2> */}
            </div>
            <div className="date-inner-wrapper">
              <div className="view-course-details-container">
                <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                  <span>Created</span>
                  <div className="view-Students-course-details-counts">
                    <p>
                      {/* {
                        Helper.formatDateTime(
                          selectedAssignment.start_date ? selectedAssignment.start_date : location?.state?.assignment?.start_date
                        ).date
                      } */}
                      {
                        Helper.formatDateTime(
                          location?.state?.assignment?.start_date
                        ).date
                      }
                    </p>
                  </div>
                </div>
                <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                  <span>Due date</span>
                  <div className="view-Students-course-details-counts">
                    <p>
                      {/* {
                        Helper.formatDateTime(
                          // student_assignment_details.length > 0 ? student_assignment_details  
                          selectedAssignment.end_date ? selectedAssignment.end_date : location?.state?.assignment?.end_date
                        ).date
                      } */}
                      {
                        Helper.formatDateTime(
                          location?.state?.assignment?.end_date
                        ).date
                      }
                    </p>
                  </div>
                </div>
              </div>
              <button type="button">Preview Assignment</button>
            </div>
          </div>
          <div className="LessonViewProgress-tab-folder-container">
            <div className="LessonViewProgress-serach-filter">
              <ReactSVG className="search-icon" src={search_icon} />
              <input
                type="text"
                name="search"
                value={search}
                onChange={handleInputChange}
                onKeyUp={hedleKeyupChnage}
                placeholder="Search by student name"
              />
            </div>
            <div className="LessonViewProgress-tab-filter-wrapper">
              <div
                className="LessonViewProgress-tab-filter-downlode-folder"
                onClick={toggledownlodefile}
              >
                <span>
                  <i className="fa-solid fa-download"></i>
                </span>
              </div>
              <div className="LessonViewProgress-filder-tab-wrapper">
                <div
                  className="AssignmentViewProgress-filter-popup-wrapper"
                  ref={filterDropdownRef}
                >
                  <div
                    className="lessonviewprogress-filder-tab"
                    onClick={() => {
                      if (!isVisiblefillter) {
                        setIsVisiblefillter(true);
                        setIsVisible(false);
                      } else {
                        setIsVisiblefillter(false);
                      }
                    }}
                  >
                    <span>
                      <i className="fa-solid fa-sliders"></i>
                    </span>
                    <p>Filter</p>
                  </div>
                  <FilterPopup
                    assignmentStatus={assignmentStatus}
                    score={score}
                    isVisiblefillter={isVisiblefillter}
                    handleApplyClick={handleApplyClick}
                    handleAssignmentStatusChange={handleAssignmentStatusChange}
                    fillterstatus={fillterstatus}
                    fillterScore={fillterScore}
                    handleScoreChange={handleScoreChange}
                  />
                </div>
              </div>
              <div className="LessonViewProgress-filder-tab-wrapper">
                <div
                  className="AssignmentViewProgress-filter-popup-wrapper"
                  ref={sortDropdownRef}
                >
                  <div
                    className="lessonviewprogress-filder-tab"
                    onClick={() => {
                      if (!isVisible) {
                        setIsVisible(true);
                        setIsVisiblefillter(false);
                      } else {
                        setIsVisible(false);
                      }
                    }}
                  >
                    <span>
                      {/* <img src={srot}/> */}
                      <i class="fa-solid fa-arrow-down-wide-short"></i>
                    </span>
                    <p>Sort</p>
                  </div>
                  <SortPopup
                    isVisible={isVisible}
                    handleSortClick={handleSortClick}
                    sortOption={sortOption}
                    onSortOptionChange={handleSortOptionChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="LessonViewProgress-table-container AssignmentViewProgress-table-container">
            <table>
              <thead>
                <tr>
                  <th>Sr no.</th>
                  <th>Student Name</th>
                  <th>Assignment Name</th>
                  <th>Started At</th>
                  <th>Completed At</th>
                  <th>Score</th>
                  {/* <th>Assignment Status</th> */}
                  {/* <th>Assignment Progress</th> */}
                  {/* <th>Score</th> */}
                  {/* <th>Student Chat</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {error === true ? (
                  <tr>
                    <td colSpan="8" className="tex-center">
                      {errormsg} No data found!!!!!!!!!!!!!!!!!!!!
                    </td>
                  </tr>
                ) : (
                  <>
                    {loading === true ? (
                      <div className="spinner-folder">
                        <LoadingComponent loading_text="Educating the Future, Loading Now.cewcwe" />
                      </div>
                    ) : (
                      // <tr>
                      //   <td colSpan="8" className="tex-center">
                      //     Loading ................
                      //   </td>
                      // </tr>
                      <>
                        {student_assignment_details &&
                          student_assignment_details.length > 0 ? (
                          student_assignment_details.map((student, index) => (
                            <tr key={student.id}>
                              <td>{inial_value + index + 1}</td>
                              <td>{student.student_name}</td>
                              <td>{student.assignment_name}</td>
                              <td>
                                <div className="startedAt-time">
                                  <span>
                                    {
                                      Helper.formatDateTime(
                                        student.started_date
                                      ).date
                                    }
                                  </span>
                                  <span>
                                    {
                                      Helper.formatDateTime(
                                        student.started_date
                                      ).time
                                    }
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="completedat-time">
                                  <span>
                                    {" "}
                                    {student.completed_date
                                      ? Helper.formatDateTime(
                                        student.completed_date
                                      ).date
                                      : "-"}
                                    {/* {
                                student.completed_date && student?.submission_data?.length > 0 ? (Helper.formatDateTime(
                                  student.started_date
                                ).date) : '-'
                              } */}
                                  </span>
                                  <span>
                                    {" "}
                                    {student.completed_date
                                      ? Helper.formatDateTime(
                                        student.completed_date
                                      ).time
                                      : ""}
                                    {/* {
                                student.completed_date && student?.submission_data?.length > 0 ? getcompletedTimer(student.submission_data, Helper.formatDateTime(
                                  student.started_date
                                ).time) : ''
                              } */}
                                  </span>
                                  {/* <img src={student_grade_flage} alt="" /> */}
                                </div>
                              </td>
                              {/* <td>
                        <div
                          className={`LessonViewProgress-student-status ${Helper.getClassNameByStatus(
                            student.status
                          )}`}
                        >
                          {student.status}
                        </div>
                      </td> */}
                              {/* <td>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{ width: `20%` }}
                          ></div>
                        </div>
                      </td> */}
                              {student?.total_marks &&
                                student?.marks_awarded ? (
                                <td
                                  style={{
                                    cursor: "pointer",
                                    color: "#0068ff",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleDropdown(
                                      0,
                                      0,
                                      student?.student_id
                                    );
                                  }}
                                >
                                  <h2 className="student-score">
                                    {student?.marks_awarded
                                      ? student?.marks_awarded
                                        ? parseFloat(student?.marks_awarded)
                                        : 0
                                      : 0}
                                    /
                                    {student?.total_marks
                                      ? student?.total_marks
                                        ? parseFloat(student?.total_marks)
                                        : 0
                                      : 0}
                                  </h2>
                                </td>
                              ) : (
                                <td>-</td>
                              )}

                              {/* <td>
                            <div className="LessonViewProgress-student-chat" onClick={(e) => {
                                      e.preventDefault();
                                      navigate(`/dashboard/messageschat/`, {
                                        state: {
                                          studentName: student.student_name, // Passing student name
                                          studentProfile: student.student_profile_pic,
                                          studentId : student.student_user_id,
                                          teacherId : student.teacher_user_id
                                        }
                                      });
                                    }}>
                                    <a> */}
                              {/* ND */}
                              {/* <img src={student.student_profile_pic} className="profile-pic-image"/>
                                  </a>
                                    <img src={massege} alt="" />
                            </div>
                          </td> */}
                              <td>
                                {student.status !== "In progress" &&
                                  student.completed_date &&
                                  student?.submission_data?.length > 0 ? (
                                  <div className="view_analytics_btn">
                                    <Button
                                      variant="underline"
                                      onClick={() => {
                                        const assignment_id = location?.state
                                          ?.assignments
                                          ? location?.state?.assignments[0]
                                            ?.assignment_id
                                          : location?.state?.assignment_id;
                                        navigate(
                                          `/course/${location.state.course.course_id}/lesson/${location.state.lesson.lesson_id}/assignment/${assignment_id}/analytics/${student.id}`,
                                          {
                                            state: {
                                              student: student,
                                              selectedAssignment: selectedAssignment,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      View analytics
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>

            <br />
            <Grid xs={12} container justifyContent={"center"}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                hideNextButton={nextUrl === null || currentPage === totalPages} // Disable if no next URL and on the last page
                hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
              />
            </Grid>
          </div>
          {showdownlodefile && (
            <div className="Download-File-popup-wrappper">
              <div className="Download-File-popup-container" data-aos="zoom-in">
                <div className="download-popup">
                  <h2>Download File as</h2>
                  <button className="close-btn" onClick={toggledownlodefile}>
                    ×
                  </button>
                  <div className="input-section">
                    <label>
                      File Name{" "}
                      <input
                        type="text"
                        name="namefiletodnld"
                        placeholder="Enter File Name"
                        value={fileNameDownload}
                        onChange={handleFileNameChange}
                      />
                      {fileNameError && (
                        <p style={{ color: "red" }}>{fileNameError}</p>
                      )}
                    </label>
                  </div>
                  <div className="button-section">
                    {/* <button className="pdf-btn">PDF (.pdf)</button>
              <span>Or</span> */}
                    <button
                      className="spreadsheet-btn"
                      onClick={download_as_excel}
                    >
                      Spreadsheet (.xlsx)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showDropdown && (
            <div className="AssignmentViewProgress-mock-test-wrapper">
              <div
                className="AssignmentViewProgress-mock-test-container"
                data-aos="zoom-in"
              >
                {
                  <QuestionNew
                    assignment={
                      analyticDetailsData?.student_answers_json[assignmentIndex]
                    }
                    ai_evaluation_json={analyticDetailsData?.ai_evaluation_json}
                    index={assignmentIndex}
                    arrayIndex={arrayIndex}
                    currentStep={assignmentIndex + 1}
                    totalSteps={
                      analyticDetailsData?.student_answers_json.length
                    }
                    handleNext={() =>
                      assignmentIndex <
                        analyticDetailsData?.student_answers_json.length - 1
                        ? setAssignmentIndex((p) => p + 1)
                        : null
                    }
                    handleBack={() => setAssignmentIndex((p) => p - 1)}
                    isLast={
                      assignmentIndex ===
                      analyticDetailsData?.student_answers_json.length - 1
                    }
                    isFirst={assignmentIndex === 0}
                    close={
                      <span
                        onClick={toggleDropdownclose}
                        className="AssignmentViewProgress-mock-test-close-btn"
                      >
                        <i className="fa-solid fa-x"></i>
                      </span>
                    }
                    questions={
                      analyticDetailsData?.ai_evaluation_json?.questions
                    }
                  />
                }
              </div>
            </div>
          )}
          {/* <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AssignmentViewProgress;