import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SchoologyRecentSyncStart {
    data: string | null;
    status: number | null,
    pending: boolean | null,
    isLoading: boolean;
    error: string | null;
}


const initialState: SchoologyRecentSyncStart = {
    data: null,
    status: null,
    pending: null,
    isLoading: false,
    error: null,
};


const schoologyRecentSyncSlice = createSlice({
    name: "schoologyRecentSync",
    initialState,
    reducers: {
        setSchoologyRecentSync: (state, action: PayloadAction<{ logs: string; status: number; pending: boolean }>) => {
            state.data = action.payload.logs;
            state.status = action.payload.status;
            state.pending = action.payload.pending;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetSchoologyRecentSync: () => initialState,
    }
})


export const { setSchoologyRecentSync, setloading, seterror, resetSchoologyRecentSync } = schoologyRecentSyncSlice.actions;
export default schoologyRecentSyncSlice.reducer;