import { Dispatch } from "redux";
import api from "../../api/api";
import { setSchoologyDetailLogs, setloading, seterror, resetschoologyDetailLogs } from "../reducers/schoologyDetailLogsReducer";

const schoologyDetailLogsData = (batch_id: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`courses/schoology-detail-logs/?batch_id=${batch_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });


        console.warn(result.data);

        if (result) {
            dispatch(setSchoologyDetailLogs(result.data))
        }
    }
    catch (error: any) {
        dispatch(seterror(error.data.message ?? "Something Went Wrong!"));
    }
}


export { schoologyDetailLogsData, resetschoologyDetailLogs };

