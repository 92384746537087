import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/dashboard.css";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { format } from "date-fns";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api, { wsURL } from "../../../../../../api/api";
import Datetime from "react-datetime";
import BackButton from "../../../../../../components/BackButton/BackButton";
import {
  Regenerate_course_image,
  course_demo_image,
} from "../../../../../../assets/images/images";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { isRequired } from "../../../../../../utils/validator";
import { Waveform } from "@uiball/loaders";
import OutlineFIleInput from "../../../../../../components/styledComponents/OutlineButton";
import { compressImage } from "../../../../../../utils/CompressImage";
import CropProfileImageModal from "../../CropProfileImageModal";
import FilteredTextField from "../../../../../../components/FilteredInput/FilteredTextField";
import Calimg from "../../../../../../assets/images/calendar.png";
import { ReactSVG } from "react-svg";
import {
  Course_created_successfully_image,
  Course_description_inner_ai_image,
  close,
} from "../../../../../../assets/images/images";
import {
  courseImageGenerateDtl,
  resetCourseImageGenerate
} from "../../../../../../state/actions/courseImageGenerateAction";

const EditCourseDetails = () => {

  const [changeImageUploadText, setChangeImageUploadText] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const { token } = useAuth();
  let { id } = useParams();

  useEffect(() => {
    if (Object.keys(location.state?.course || {}).length !== 0) {

      localStorage.setItem('courseDetails', JSON.stringify(location.state?.course))
    }
  }, [])

  const course = JSON.parse(localStorage.getItem('courseDetails')) || {};

  const teacher_id =
    location.state?.teacher_id ??
    globalState.teacher_details?.data?.teacher_id ??
    "";
  const [loading, setLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);

  useEffect(() => {

    const storedCourse = JSON.parse(localStorage.getItem('courseDetails'));
    if (storedCourse) {
      setSelectedImage(storedCourse.image);
      setTempImage(storedCourse.image);
      setUplodedImage(storedCourse.image);
      setFormState(prev => ({
        name: storedCourse?.name ?? "",
        start_date: storedCourse?.start_date ?? "",
        end_date: storedCourse?.end_date ?? "",
        description: storedCourse?.description ?? "",
        image: storedCourse?.image ?? "",
        image_url: "",
        teacher: teacher_id,
        is_active: true,
        from_grade:
          typeof storedCourse?.from_grade === "object"
            ? Object.keys(storedCourse?.from_grade ?? "")
            : typeof from_grade === "string"
              ? storedCourse?.from_grade ?? ""
              : "",
        to_grade:
          typeof storedCourse?.to_grade === "object"
            ? Object.keys(storedCourse?.to_grade ?? "")
            : typeof to_grade === "string"
              ? storedCourse?.to_grade ?? ""
              : "",
      }));
    }
  }, []);

  const [selectedImage, setSelectedImage] = useState(JSON.parse(localStorage.getItem('courseDetails'))?.image ?? '');
  const [clickCount, setClickCount] = useState(Number(localStorage.getItem("clickCount")) || 0);
  const [getSocket, setSocket] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isRegenerate, setIsRegenerate] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const getcourseImage = useSelector((state) => state.getcourseImage);
  const [tempImage, setTempImage] = useState(course?.image ?? "");
  const [uplodedImage, setUplodedImage] = useState(course?.image ?? "");

  const teacherSubjects = globalState?.teacher_details?.data?.subjects;
  const subjectsArray = teacherSubjects
    ? Object.entries(teacherSubjects)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.value.localeCompare(b.value))
    : [];
  subjectsArray.push({ key: "others", value: "Others" });
  const [createLoading, setCreateLoading] = useState(false);
  const [waveloading, setwaveloading] = useState(false);
  const [formState, setFormState] = useState({
    name: course?.name ?? "",
    start_date: course?.start_date ?? "",
    end_date: course?.end_date ?? "",
    description: course?.description ?? "",
    image: course?.image ?? "",
    image_url: "",
    teacher: teacher_id,
    is_active: true,
    from_grade:
      typeof course?.from_grade === "object"
        ? Object.keys(course?.from_grade ?? "")
        : typeof from_grade === "string"
          ? course?.from_grade ?? ""
          : "",
    to_grade:
      typeof course?.to_grade === "object"
        ? Object.keys(course?.to_grade ?? "")
        : typeof to_grade === "string"
          ? course?.to_grade ?? ""
          : "",
  });


  const [formErrors, setFormErrors] = useState({
    name: "",
    subject: "",
    from_grade: "",
    to_grade: "",
    start_date: "",
    end_date: "",
    description: "",
    image: "",
  });
  const timestamp = Date.now();



  const newWebSocketURL = `${wsURL}ws/course_descrption/${timestamp}/`;

  let combinedMessage = [];
  // Create the WebSocket connection when the component mounts
  useEffect(() => {
    // Create a WebSocket connection with the new URL
    const socket = new WebSocket(newWebSocketURL);
    // Set the socket state variable
    setSocket(socket);

    // Event handler for when the WebSocket connection is established
    socket.addEventListener("open", (event) => { });
    document.body.style.overflow = "auto !important";
    // Event handler for incoming messages from the WebSocket server
    socket.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data && data.message) {
          const message = data.message;

          combinedMessage.push(message);
          let status = false;
          let chatdatares = combinedMessage.join("");

          if (
            chatdatares.includes("<STARTOFTURN>") ||
            chatdatares.includes("<ENDOFTURN>")
          ) {
            chatdatares = chatdatares
              .replace(/<STARTOFTURN>/g, "")
              .replace(/<ENDOFTURN>/g, "");

            if (combinedMessage.join("").includes("<ENDOFTURN>")) {
              status = true;
              combinedMessage.length = 0;
              combinedMessage.push(chatdatares);
            }
          }
          setFormState((prev) => {
            if (status) {
              combinedMessage.length = 0;
            }
            return {
              ...prev,
              description: chatdatares
                .replace("<STARTOFTURN>", "")
                .replace("<ENDOFTURN>", ""),
            };
          });

          setwaveloading(false);
          setFormErrors((prevErrors) => ({ ...prevErrors, description: "" }));
        } else {
          setwaveloading(false);
        }
      } catch (error) {
        setwaveloading(false);
      }
    });
    socket.addEventListener("close", (event) => {
    });
    socket.addEventListener("error", (error) => { });

    return () => {
      if (socket.readyState === 1) {
        socket.close();
      }
    };
  }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isValidStartDate = (current) => {
    const today = dayjs().startOf('day'); // Today at 00:00
    return current.isSameOrAfter(today); // Allow today and future dates
  };

  const isValidEndDate = (current) => {

    // Ensure there's a start date selected
    if (!formState.start_date) {
      return false; // Disable all dates if no start date is selected
    }

    // Calculate the minimum end date as one day after the start date
    const minEndDate = dayjs(formState.start_date).add(1, 'day');
    return dayjs(current).isAfter(minEndDate, 'day'); // 'isAfter' will ensure the date is at least the day after minEndDate
  };

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = (saveChanges) => {
    if (saveChanges) {
      setUplodedImage(tempImage);
    } else {

      setSelectedImage(uplodedImage);
    }
    setModalIsOpen(false);
  };



  const handleSaveCroppedImage = (croppedImage) => {
    setTempImage(croppedImage);
    onCloseModal(true);
  };

  const handleCancelCroppedImage = () => {
    onCloseModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "description") {
      error = isRequired(value);
    } else if (name === "from_grade") {
      error = isRequired(value);
    } else if (name === "to_grade") {
      error = isRequired(value);
    } else if (name === "subject") {
      error = isRequired(value);
    } else if (name === "start_date") {
      error = isRequired(value);
    }
    // else if (name === 'end_date') {
    //     error = isRequired(value);
    // }
    else if (name === "image") {
      error = isRequired(value);
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const YYYYMMDD = (date) => {
    try {
      return format(new Date(date ?? ""), "yyyy-MM-dd");
    } catch (error) {
      return "";
    }
  };

  const handleInputChangedate = (name, value) => {
    let error = "";
    if (name === "start_date") {
      error = isRequired(YYYYMMDD(value));
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    if (YYYYMMDD(value)) {
      setFormState((prev) => ({
        ...prev,
        [name]: YYYYMMDD(value),
      }));
    } else {
      setFormState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";

    if (getcourseImage?.message) {
      toast.success(getcourseImage.message, {
        position: toast.POSITION.TOP_CENTER,
      });

      setImageUploaded(true);
      setSelectedImage(getcourseImage.message.image_urls);
      setUplodedImage(getcourseImage.message.image_urls);

      setFormState((prev) => ({
        ...prev,
        image_url: getcourseImage.message.image_urls,
        image: null,
      }));
      setClickCount(clickCount + 1);
      setFormState((prev) => ({ ...prev, image_type: 1 }));
      setIsRegenerate(false);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
        image_url: "",
      }));
    } else if (getcourseImage?.error) {
      toast.error(getcourseImage.error || failedErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetCourseImageGenerate());
    }
  }, [getcourseImage, dispatch]);

  useEffect(() => {
    localStorage.setItem("clickCount", clickCount);
  }, [clickCount]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("clickCount");
      dispatch(resetCourseImageGenerate());
    };
  }, []);
  const generatesImges = async () => {
    setChangeImageUploadText(false);
    if (clickCount >= 3) {
      setErrorMessage(
        "The AI image generation has reached its three-attempt limit."
      );
      return;
    }

    setImageUploaded(false);
    setIsRegenerate(true);
    setSelectedImage(null);
    const formdata = {
      Course_name: formState.name || "",
      Subject: getSubjectData(course.subject),
      Grade: `${gradeArray.find((grd) => grd.key === formState.from_grade[0])
        ?.value ?? ""}-${gradeArray.find(
          (grd) => grd.key === formState.to_grade[0]
        )?.value ?? ""}`,
      Course_description: formState.description || "",
    };
    dispatch(courseImageGenerateDtl(formdata));
  };
  function EnhancedDescription({
    courseName,
    subject,
    from_grade,
    to_grade,
    keywords,
  }) {
    // Function to enhance the description  using the WebSocket connection
    const enhanceDescription = (e) => {
      e.preventDefault();
      setwaveloading(true);

      if (getSocket) {
        // Create a JSON payload to send over the WebSocket
        const payload = {
          course_name: courseName,
          subject: subject,
          start_grade: gradeArray.find((grd) => grd.key === from_grade)?.value ?? "",
          end_grade: gradeArray.find((grd) => grd.key === to_grade)?.value ?? "",
          keywords: keywords,
        };
        const payloadJSON = JSON.stringify(payload);

        // Send the JSON data over the WebSocket connection
        getSocket.send(payloadJSON);
      }
      // Add functionality to enhance the description here
    };

    return (
      <button
        onClick={enhanceDescription}
        type="button"
        disabled={
          !formState.name ||
          !course.subject ||
          !formState.from_grade ||
          !formState.to_grade ||
          !formState.start_date ||
          waveloading
        }
        className="Course-description-inner-ai-feilds"
      >
        <div className="Course-description-inne r-ai-image">
          <img src={Course_description_inner_ai_image} alt="" />
        </div>
        <span>Enhance Course Description</span>
      </button>
    );
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleFileInput = async (event) => {
    setFormState((prev) => ({ ...prev, image_type: "" }));
    // Show loader when file input is created
    setImageUploading(true);
    setSelectedImage(null);
    // Reset imageUploaded to false whenever a new file is selected
    setImageUploaded(false);

    const file = event.target.files[0];
    if (file) {
      try {
        // Compress the image
        const compressedImage = await compressImage(file);

        const base64Image = await toBase64(compressedImage);
        // localStorage.setItem('selectedImage', base64Image);
        setSelectedImage(base64Image);

        // setSelectedImage(URL.createObjectURL(compressedImage));

        // Set the temporary image to be potentially cropped and saved
        setTempImage(URL.createObjectURL(compressedImage));

        onOpenModal();

        setFormErrors({
          ...formErrors,
          image: "",
        });
        setFormState((prev) => ({ ...prev, image_type: 2 }));
      } catch (error) {
        console.error("Image compression failed:", error);
        setSelectedImage(null);
        // Handle error and set form error
        setFormErrors({
          ...formErrors,
          image: "Image compression failed.",
        });
      } finally {
        // Hide loader when compression is complete
        setImageUploading(false);
      }
    } else {
      setSelectedImage(null);
      // Hide loader when no file is selected (cancel)
      setImageUploading(false);
    }
  };
  const getSubjectData = (subject) => {
    if (typeof subject === "object" && subject !== null) {
      const firstKey = Object.keys(subject)[0];
      return subject[firstKey];
    } else if (typeof subject === "string") {
      return subject;
    } else {
      return "";
    }
  };
  const onViewCourseClick = async (course) => {
    navigate(`/course/${course.course_id}/view`, {
      state: { course, teacher_id },
    });
  };
  const handleSubmit = async (e) => {

    e.preventDefault();
    let errors = {};

    errors.name = isRequired(formState.name);
    errors.start_date = isRequired(formState.start_date);
    errors.description = isRequired(formState.description);
    if (formState.image_type === 2) {
      errors.image = isRequired(formState.image);
    } else if (formState.image_type === 1) {
      errors.image = isRequired(formState.image_url);
    } else {
      if (formState.image === '' && formState.image_url === '') {
        errors.image = isRequired(formState.image === '' ? formState.image : formState.image_url);

      }
    }

    setFormErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      await onEditCourse();

    }
  };
  const onEditCourse = async () => {
    setLoading(true);
    toast.dismiss();
    let name = formState.name;
    let description = formState.description;
    let end_date = formState.end_date;
    let start_date = formState.start_date;
    let image = formState.image;
    let image_url = formState.image_url;
    let to_grade = formState.to_grade;
    let from_grade = formState.from_grade;
    let payload = new FormData();

    if (end_date && end_date !== course?.end_date) {
      payload.append("end_date", end_date);
    } else if (!end_date) {
      // Here you can decide whether to append a null value or not append the end_date at all
      payload.append("end_date", ""); // or payload.append('end_date', null);
    }

    if (name !== course?.name) payload.append("name", name);
    if (description !== course?.description)
      payload.append("description", description);
    if (start_date !== course?.start_date)
      payload.append("start_date", start_date);
    if (image !== uplodedImage && !image_url) payload.append("image", image);
    if (image_url) payload.append("image_url", image_url);
    if (to_grade !== course?.to_grade) payload.append("to_grade", to_grade);
    if (from_grade !== course?.from_grade)
      payload.append("from_grade", from_grade);
    payload.append("push_to_schoology", true);

    try {
      const response = await api.put(
        `/courses/update-course/${id}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        onViewCourseClick(response?.data?.data);
        toast.success("Course updated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("clickCount");
        setLoading(false);
      } else {
        toast.error("Failed to update course.", {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update course.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  let grade_details;
  try {
    grade_details = globalState.grade_details?.grade;
  } catch (error) { }
  const gradeArray =
    grade_details && grade_details
      ? Object.entries(grade_details).map(([key, value]) => ({ key, value }))
      : [];


  const getKeyByvalue = (value) => {
    if (value) {
      const item = gradeArray.find((obj) => obj.value === value);
      const key = item ? item.key : null;
      return key;
    } else {
      return "";
    }
  };
  function getToGradeArray(from_grade, grades) {
    let to_grade_array = [];

    // Determine the starting grade key
    let startGradeKey;
    if (Array.isArray(from_grade) && from_grade.length > 0) {
      startGradeKey = from_grade[0];
    } else {
      startGradeKey = from_grade;
    }

    // If startGradeKey is not determined or invalid, return an empty array
    if (!startGradeKey || isNaN(startGradeKey)) return [];

    // Populate to_grade_array
    for (let key in grades) {
      if (parseInt(key) >= parseInt(startGradeKey)) {
        to_grade_array.push({ key: key, value: grades[key] });
      }
    }
    return to_grade_array;

  }
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);

  const resetEndDate = () => {
    setFormState(prev => ({
      ...prev,
      end_date: null  // Reset to null or empty string depending on how your state is expected
    }));
  };

  return (
    <>
      <div className="dashboard" id="edit-course-Details-section">
        <form
          className="edit-course-Details-wrapper"
          action=""
          onSubmit={handleSubmit}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Back"} />
                </div>
                <div className="edit-course-details-container">
                  <div className="edit-course-details-folder">
                    <div className="uploded-image-section">
                      <FormControl
                        margin="none"
                        fullWidth
                        error={!!formErrors.image}
                      >
                        <div className="Create-Course-image">
                          <div className="Create-Course-image-disclamer">
                            <p>Course Image</p>
                          </div>

                          {

                          }
                          {uplodedImage ? (
                            <>
                              <div className="course-main-image">
                                <ReactSVG
                                  className="course-image-close"
                                  src={close}
                                  onClick={() => {
                                    setFormState((prev) => ({
                                      ...prev,
                                      image: "",
                                      image_url: "",
                                    }));
                                    setUplodedImage(null);
                                    setImageUploaded(false);
                                    setSelectedImage(null);
                                    setChangeImageUploadText(false);
                                  }}
                                />
                                <img
                                  className="course-uploded-image"
                                  src={
                                    imageUploaded ? selectedImage : uplodedImage
                                  }
                                  alt=""
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              className="course-file-image"
                              style={
                                !!formErrors.image
                                  ? { border: "2px #d32f2f dashed" }
                                  : null
                              }
                            />

                          )}

                          <div className="Create-Course-uplode-image-container">
                            {!uplodedImage && (
                              <>
                                <div className="Create-Course-uisng-ai">
                                  {isRegenerate && (
                                    <img
                                      className={`loding ${isRegenerate ? "spinning" : ""
                                        }`}
                                      src={Regenerate_course_image}
                                      alt=""
                                    />
                                  )}
                                  <button
                                    type="button"
                                    onClick={generatesImges}
                                    className={`course-Generating-AI-btn`}
                                    disabled={
                                      !formState.name ||
                                      !formState.description ||
                                      !formState.from_grade ||
                                      !formState.start_date ||
                                      isRegenerate
                                    }
                                  >
                                    Generate using AI
                                  </button>
                                </div>
                                <div className="Create-Course-uisng-ai uplode-image">
                                  <OutlineFIleInput
                                    label={"Upload Image"}
                                    handleFileInput={handleFileInput}
                                  />
                                  {imageUploading && (
                                    <img
                                      className={` ${imageUploading ? "spinning" : ""
                                        }`}
                                      src={Regenerate_course_image}
                                      alt=""
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {uplodedImage && (
                          <>
                            <div className="reuplode-image-folder">
                              <div
                                className="Create-Course-uplode-outer-image"
                                style={
                                  isRegenerate
                                    ? { pointerEvents: "none", opacity: "0.5" }
                                    : {}
                                }
                              >
                                <OutlineFIleInput
                                  label={
                                    changeImageUploadText
                                      ? "Re-Upload Image"
                                      : "Upload Image"
                                  }
                                  handleFileInput={(e) => {
                                    setSelectedImage(null);
                                    handleFileInput(e);
                                  }}
                                />
                              </div>
                              <div className="Regenerate-Course-image">
                                <img
                                  className={`Regenerate-image ${isRegenerate ? "spinning" : ""
                                    }`}
                                  src={Regenerate_course_image}
                                  alt=""
                                />
                                <button
                                  disabled={
                                    !formState.name ||
                                    !formState.description ||
                                    !formState.from_grade ||
                                    !formState.start_date ||
                                    isRegenerate
                                  }
                                  onClick={generatesImges}
                                  type="button"
                                  className="Regenerate-course-btn"
                                >
                                  {changeImageUploadText
                                    ? "Generate"
                                    : "Regenerate"}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {errorMessage && (
                          <div className="limiterrorMessage">
                            <span>{errorMessage}</span>
                          </div>
                        )}

                        <FormHelperText>{formErrors.image}</FormHelperText>
                      </FormControl>
                    </div>
                    <div className="edit-course-details-right-section">
                      <div className="edit-course-details-inner-input-container">
                        <div className="edit-Course-inner-input">
                          <label>Course Name</label>
                          <FormControl
                            margin="none"
                            fullWidth
                            error={!!formErrors.name}
                          >
                            <TextField
                              name="name"
                              placeholder="Eg. Fundamentals of Neuroscience"
                              variant="outlined"
                              size="small"
                              value={formState.name}
                              error={!!formErrors.name}
                              onChange={handleInputChange}
                              InputProps={{
                                sx: {
                                  borderRadius: "12px",
                                  backgroundColor: "#F5F5F5",
                                },
                              }}
                            />
                            <FormHelperText>{formErrors.name}</FormHelperText>
                          </FormControl>
                        </div>
                        <div className="create-course-Grade-input-folder">
                          <div className="Create-Course-inner-input create-course-inner-input-grade">
                            <label>Level</label>
                            <FormControl
                              margin="none"
                              fullWidth
                              error={!!formErrors.from_grade}
                            >
                              <TextField
                                name="from_grade"
                                placeholder="Eg. SQL"
                                variant="outlined"
                                select
                                size="small"
                                error={!!formErrors.from_grade}
                                value={formState.from_grade}
                                onChange={handleInputChange}
                                defaultValue={formState.from_grade || ""}
                                InputProps={{
                                  sx: {
                                    borderRadius: "12px",
                                    backgroundColor: "#F5F5F5",
                                    paddingRight: "0px",
                                    fontSize: "13px",
                                  },
                                }}
                              >
                                {gradeArray.map((option) => (
                                  <MenuItem
                                    key={`gradeArray1${option.key}`}
                                    value={option.key}
                                  >
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <FormHelperText>
                                {formErrors.from_grade}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className="Create-Course-inner-input create-course-inner-input-grade">
                            <label>To Level</label>
                            <FormControl
                              margin="none"
                              fullWidth
                              error={!!formErrors.to_grade}
                            >
                              <TextField
                                name="to_grade"
                                placeholder="Eg. SQL"
                                variant="outlined"
                                select
                                size="small"
                                error={!!formErrors.to_grade}
                                value={formState.to_grade}
                                onChange={handleInputChange}
                                defaultValue={formState.to_grade || ""}
                                InputProps={{
                                  sx: {
                                    borderRadius: "12px",
                                    backgroundColor: "#F5F5F5",
                                    paddingRight: "0px",
                                    fontSize: "13px",
                                  },
                                }}
                              >
                                {
                                  getToGradeArray(
                                    formState.from_grade,
                                    globalState.grade_details?.grade ?? {}
                                  ).map((option) => (
                                    <MenuItem
                                      key={`gradeArray2${option.key}`}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  ))}
                              </TextField>
                              <FormHelperText>
                                {formErrors.to_grade}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      <div className="edit-course-date-and-price-container">
                        <div className="edit-Course-inner-input Course-Start-Date">
                          <label>Course Start Date</label>
                          <FormControl
                            margin="none"
                            fullWidth
                            error={!!formErrors.start_date}
                          >
                            <DesktopDatePicker
                              name="start_date"
                              variant="outlined"
                              value={dayjs(formState.start_date)}
                              minDate={dayjs(formState.start_date)}
                              error={!!formErrors.start_date}
                              onChange={(date) =>
                                handleInputChangedate("start_date", date)
                              }
                              slots={{ openPickerIcon: CalendarMonthIcon }}
                              open={open1}
                              onClose={() => setopen1(false)}
                              onOpen={() => setopen1(true)}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                  InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                      borderRadius: "12px",
                                      backgroundColor: "#F5F5F5",
                                    },
                                  }}
                                  onClick={() => setopen1(true)}
                                  size="small"
                                />
                              )}
                              slotProps={{
                                field: { setopen1 },
                                inputAdornment: {
                                  position: "start",
                                },
                                textField: {
                                  onClick: () => setopen1(true),
                                  size: "small",
                                  InputProps: {
                                    sx: {
                                      borderRadius: "12px",
                                      backgroundColor: "#F5F5F5",
                                    },
                                  },
                                },
                              }}
                            />

                            <FormHelperText>
                              {formErrors.start_date}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <div className="edit-Course-inner-input Course-Start-Date" id="edit-course-end-date">
                          <label>
                            Course End Date <span>(Optional)</span>
                          </label>
                          {formState.end_date && (
                            <i onClick={() => resetEndDate()} className="fa-solid fa-x"></i>
                          )}
                          <FormControl
                            margin="none"
                            fullWidth
                            error={!!formErrors.end_date}
                          >
                            <DesktopDatePicker
                              name="end_date"
                              variant="outlined"
                              value={
                                formState.end_date === ""
                                  ? null
                                  : dayjs(formState.end_date)
                              }
                              error={!!formErrors.end_date}
                              onChange={(date) =>
                                handleInputChangedate("end_date", date)
                              }
                              slots={{ openPickerIcon: CalendarMonthIcon }}
                              open={open2}
                              onOpen={() => setopen2(true)}
                              onClose={() => setopen2(false)}
                              minDate={dayjs(formState.start_date)?.add(
                                1,
                                "day"
                              )}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                  InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                      borderRadius: "12px",
                                      backgroundColor: "#F5F5F5",
                                    },
                                  }}
                                  onClick={() => setopen2(true)}
                                  size="small"
                                />
                              )}
                              slotProps={{
                                field: { setopen2 },
                                inputAdornment: {
                                  position: "start",
                                },
                                textField: {
                                  onClick: () => setopen2(true),
                                  size: "small",
                                  InputProps: {
                                    sx: {
                                      borderRadius: "12px",
                                      backgroundColor: "#F5F5F5",
                                    },
                                  },
                                },
                              }}
                            />
                            <FormHelperText>{formErrors.end_date}</FormHelperText>
                          </FormControl>
                        </div>
                      </div>
                      <div className="edit-Course-inner-textarea">
                        <label>Course Description</label>
                        <FormControl
                          margin="none"
                          fullWidth
                          error={!!formErrors.description}
                        >
                          <TextField
                            id="create-course-description"
                            name="description"
                            multiline
                            variant="outlined"
                            size="small"
                            cols="30"
                            rows="5"
                            value={formState.description || ""}
                            placeholder="Enter a short description about your course "
                            error={!!formErrors.description}
                            onChange={handleInputChange}
                            InputProps={{
                              sx: {
                                borderRadius: "12px",
                                backgroundColor: "#F5F5F5",
                              },
                              style: {
                                height: "110px",
                                fontSize: "12px",
                                lineHeight: "20px",
                              },
                            }}
                          />
                          <FormHelperText>
                            {formErrors.description}
                          </FormHelperText>
                        </FormControl>
                        {waveloading ? <Waveform /> : <></>}
                        <div className="create-course-enhance-course-folder">
                          <div className="Course-description-inner-ai-feilds-folder">
                            <EnhancedDescription
                              courseName={formState.name || ""}
                              subject={getSubjectData(course.subject || "")}
                              from_grade={formState.from_grade || ""}
                              to_grade={formState.to_grade || ""}
                              keywords={formState.description || ""}
                              error={!!formErrors.description}
                            />
                          </div>
                        </div>
                        {/* {errorMessage && (
                          <div className="limiterrorMessage">
                            <span>{errorMessage}</span>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className="loading-spinner" />
                  ) : (
                    <button
                      type="submit"
                      disabled={waveloading}
                      style={{ backgroundColor: waveloading && 'grey' }}
                      className={"Update-Publish-btn"}
                    >
                      Update and Publish
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {modalIsOpen && (
        <CropProfileImageModal
          src={tempImage}
          setSrc={setTempImage}
          onSave={handleSaveCroppedImage}
          onCancel={handleCancelCroppedImage}
          setSelectedImage={setSelectedImage}
          setImageUploaded={setImageUploaded}
          setUplodedImage={setUplodedImage}
          modalStatus={modalIsOpen}
          setModalClose={onCloseModal}
          fieldName={"image"}
          setFormState={setFormState}
          formState={formState}
          aspectRatio={4 / 3}
        />
      )}
    </>
  );
};

export default EditCourseDetails;
