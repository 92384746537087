import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CourseState {
    data: any[];
    isLoading: boolean;
    error: string | null
}

const initialState: CourseState = {
    data: [],
    isLoading: false,
    error: null
}

const schoologyListCourseSlice = createSlice({
    name: 'schoologyListCourse',
    initialState,
    reducers: {
        setCourseList: (state: CourseState, action: PayloadAction<any[]>) => {
            state.data = action.payload
            state.isLoading = false
            state.error = null
        },
        setloading: (state: CourseState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: CourseState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetCourseList: () => {
            return initialState;
        },
    }
})





export const { setCourseList, setloading, seterror, resetCourseList } = schoologyListCourseSlice.actions;
export default schoologyListCourseSlice.reducer;