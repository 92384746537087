import React from "react";
import "../../../src/components/ConformationPopUp/ConformationPopup.css";

const ConformatioPopup = ({ title, No, Yes, show, close }) => {

    return (
        <>
            <div className="Conformatio-popup-section">
                <div className="Conformatio-popup-wrapper">
                    <div className="Conformatio-popup-container">
                        <h2>{title}</h2>
                        <div className="Conformatio-popup-btn">
                            <button type="submit" onClick={show} className="Course-close">{Yes}</button>
                            <button type="submit" onClick={close}>{No}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConformatioPopup;