import React, { useEffect, useRef, useState } from "react";
import { schoology_profile, warning_syncwithschoology_icon } from "../../assets/images/images";
import "./SyncwithSchoology.css";
import BackButton from "../BackButton/BackButton";
import { LinearProgress, styled, Tooltip } from '@mui/material';
import { syncWithSrdatabase, resetSyncSchoologydatabase } from '../../../src/state/actions/syncWithSrdsDatabaseAction'
import { schoologyRecentSync, resetSchoologyRecentSync } from '../../../src/state/actions/schoologyRecentSyncAction'
import { importSchoologyCourseSync, resetImportSchoologyCourse } from '../../../src/state/actions/importSchoologyCourseAction'
import { schoologyDetailLogsData, resetschoologyDetailLogs } from '../../../src/state/actions/schoologyDetailLogsAction'
import { syncWithSrds, resetSyncSchoology } from '../../../src/state/actions/syncWithSrdsAction'
import { useDispatch } from "react-redux";
import { useAuth } from "../../features/auth/AuthContext";
import { useSelector } from "react-redux";
import LodingAnimation from "../LodingAnimation/LodingAnimation";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import ConformatioPopup from "../ConformationPopUp/ConformationPopup";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import CongratulationsPopup from "../CongratulationsPopup/CongratulationsPopup";
import {
    check_image
} from "../../assets/images/images";
import { useNavigate } from "react-router-dom";



function SyncwithSchoology() {
    const { token } = useAuth()
    const syncWithSrdsDatabase = useSelector((state) => state.syncDatabaseCoursesWithSrds);
    const importSchoologyCourse = useSelector((state) => state.importSchoologyCourse);
    const syncWithSrdsCourses = useSelector((state) => state.syncWithSrds);
    const getSchoologyRecentSync = useSelector((state) => state.getSchoologyRecentSync);
    const schoologyDetailLogs = useSelector((state) => state.schoologyDetailLogs);
    const globalState = useSelector((state) => state.globalState);
    const teacher_details = globalState?.teacher_details?.data
    const dispatch = useDispatch();
    const navigate = useNavigate();




    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedDetailCourses, setSelectedDetailCourses] = useState([]);
    const [uuid, setUuid] = useState([]);
    const [selectedCoursesId, setSelectedCoursesId] = useState([]);
    const [syncCoursePage, setSyncCoursePage] = useState(false);
    const [startSync, setStartSync] = useState(false);
    const [mobileUser, setMobileUser] = useState(false);
    const [errorCount, seterrorCount] = useState(0);
    const [congratulationsPopUp, setCongratulationsPopUp] = useState(false);
    const [getCourses, setGetCourses] = useState([]);
    const [search, setsearch] = useState('');
    const [courses, setCourses] = useState([]);
    const [activeTab, setActiveTab] = useState("All Courses");
    const [searchedCourses, setSearchedCourses] = useState([]);
    const [progress, setProgress] = useState([]); // Initialize progress for each course
    const [currentIndex, setCurrentIndex] = useState(0); // Track current active progress bar
    const [openDialog, setOpenDialog] = useState(false);
    const startSyncRef = useRef(startSync);
    const uuidRef = useRef(uuid);
    const selectedCoursesIdRef = useRef(selectedCoursesId);
    const [summeryPopup, setSummeryPopup] = useState(false);
    const [coursesLength, setCoursesLength] = useState({
        allCourses: 0,
        syncedCourses: 0,
        notSyncedCourses: 0
    });

    const coursesContainerRef = useRef(null);



    // updating the progress bar and get id's of selecting courses from user 

    useEffect(() => {
        setProgress(Array(selectedCourses.length).fill(0))
        setSelectedCoursesId(selectedCourses.map((course) => course.section_id));
        selectedCoursesIdRef.current = selectedCourses.map((course) => course.section_id)
    }, [selectedCourses])



    // here i again call the import course API if user unmount the component

    useEffect(() => {

        return () => {
            if (startSyncRef.current && selectedCoursesIdRef.current.length > 0) {
                setTimeout(() => {
                    dispatch(importSchoologyCourseSync({ "section_ids": selectedCoursesIdRef.current, 'batch_id': uuidRef.current }, token));
                }, 3000); // Delay API call by 3 seconds
            }

            dispatch(resetImportSchoologyCourse());
        };
    }, []);



    // here i do API polling if recent sync course have any pending status of course. 

    useEffect(() => {
        let pollingInterval;

        const startPolling = async () => {
            if (getSchoologyRecentSync.pending) {
                pollingInterval = setTimeout(() => RecentSyncAPI(token), 6000); // Retry after 6 seconds
            }
        };

        // Start polling when the component mounts
        startPolling();

        // Clean up polling on component unmount
        return () => clearTimeout(pollingInterval);
    }, [getSchoologyRecentSync]); // Dependency to re-run effect if `isPending` changes



    // after all courses synce i navigate user to dashboard 

    useEffect(() => {
        if (startSync && (currentIndex >= selectedCourses.length)) {
            setCongratulationsPopUp(true);

            setTimeout(() => {
                setCongratulationsPopUp(false); // Reset state to `false` after 2 seconds
                navigate('/')
            }, 2000);
        }
    }, [currentIndex, startSync])



    // get a unique id from uuidv4 library

    useEffect(() => {
        const id = uuidv4()
        setUuid(id);
        uuidRef.current = id;
    }, [])



    // here i get user is use mobile device or dekstop device 

    useEffect(() => {
        const handleResize = () => {
            // setIsSmallScreen(window.innerWidth < 1000);
            if (window.innerWidth < 767) {
                setMobileUser(true)
            } else {
                setMobileUser(false)
            }
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Check initial value
        handleResize();

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    // here i update the progress bar 

    useEffect(() => {
        if (!startSync) return;

        if (currentIndex >= selectedCourses.length) return; // Exit if all progress bars are done
        if (importSchoologyCourse.status !== null) {
            if (importSchoologyCourse.data) {
                // Set the current progress bar to 100% and move to the next
                setProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[currentIndex] = 100; // Set current progress to 100%
                    // setStartSync(false)
                    return newProgress;
                });

                dispatch(resetImportSchoologyCourse())

                if (currentIndex + 1 < selectedCourses.length) {
                    setTimeout(() => {
                        const updatedCourses = selectedCoursesId.filter(id => id !== selectedCourses[currentIndex + 1].section_id);
                        setSelectedCoursesId(updatedCourses);
                        selectedCoursesIdRef.current = updatedCourses;

                        dispatch(importSchoologyCourseSync({
                            "section_ids": [selectedCourses[currentIndex + 1].section_id],
                            "batch_id": uuid
                        }, token));

                    }, 3000); // Delay API call by 3 seconds

                    setTimeout(() => setCurrentIndex((prevIndex) => prevIndex + 1), 0); // Move to the next progress bar
                }

                if (currentIndex === selectedCourses.length - 1) {
                    setTimeout(() => setCurrentIndex((prevIndex) => prevIndex + 1), 0);
                }

                return; // Stop further execution of useEffect for the current bar
            } else {
                seterrorCount((prevCount) => prevCount + 1);
                setProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[currentIndex] = 404; // Set current progress to 100%
                    return newProgress;
                });

                dispatch(resetImportSchoologyCourse())

                if (currentIndex + 1 < selectedCourses.length) {

                    setTimeout(() => {
                        const updatedCourses = selectedCoursesId.filter(id => id !== selectedCourses[currentIndex + 1].section_id);
                        setSelectedCoursesId(updatedCourses);
                        selectedCoursesIdRef.current = updatedCourses
                        dispatch(importSchoologyCourseSync({ "section_ids": [selectedCourses[currentIndex + 1].section_id], 'batch_id': uuid }, token))
                    }, 3000); // Delay API call by 3 seconds

                    setTimeout(() => setCurrentIndex((prevIndex) => prevIndex + 1), 0); // Move to the next progress bar
                }


                if (currentIndex === selectedCourses.length - 1) {
                    setTimeout(() => setCurrentIndex((prevIndex) => prevIndex + 1), 0);
                }

                return; // Stop further execution of useEffect for the current bar

            }
        }



        const maxProgress = 99; // Target maximum value


        const updateProgress = () => {
            setProgress((prevProgress) => {
                const newProgress = [...prevProgress];

                if (newProgress[currentIndex] >= 100) {
                    return newProgress;
                }

                // Decide randomly whether to increase randomly or sequentially
                const isRandom = Math.random() > 0.5; // 50% chance
                let increment;

                if (isRandom) {
                    // Random increment between 5% and 10%
                    increment = Math.random() * (20 - 5) + 5;
                } else {
                    // Sequential increment (e.g., 1%)
                    increment = 1;
                }

                // Increment progress
                newProgress[currentIndex] = Math.min(
                    maxProgress,
                    newProgress[currentIndex] + increment
                );

                return newProgress;
            });
            // Introduce a random delay between updates (2 to 3 seconds)
            const randomDelay = Math.random() * (3000 - 2000) + 2000;
            setTimeout(updateProgress, randomDelay);
        };

        updateProgress(); // Start the progress updates

        // return () => { dispatch(resetImportSchoologyCourse()) }; // No need to clean up since we use `setTimeout`
    }, [currentIndex, importSchoologyCourse, startSync]); // Include demoData to re-trigger when its state changes



    // here i call the recent sync API

    function RecentSyncAPI(token) {
        dispatch(schoologyRecentSync(token))
    }



    // here i call the API and get the recent sync data and course list data

    useEffect(() => {
        RecentSyncAPI(token)
        dispatch(syncWithSrdatabase(token))
        return () => {
            dispatch(resetSyncSchoologydatabase())
            dispatch(resetSyncSchoology())
            dispatch(resetSchoologyRecentSync())
            dispatch(resetschoologyDetailLogs())
        }
    }, [])



    // here i store the course list data 

    useEffect(() => {
        if (syncWithSrdsCourses.data) {
            if (syncWithSrdsCourses.status === 200) {
                toast.success('Courses refreshed successfully')
                setGetCourses(syncWithSrdsCourses.data)
                setCourses(syncWithSrdsCourses.data)
                setSearchedCourses(syncWithSrdsCourses.data)
                const coursesWithoutNullUrl = syncWithSrdsCourses.data.filter(course => course.course_url !== null);
                const coursesWithNullUrl = syncWithSrdsCourses.data.filter(course => course.course_url === null);

                setCoursesLength((prevCoursesLength) => ({
                    ...prevCoursesLength,
                    allCourses: syncWithSrdsCourses.data.length, // Replace with actual value
                    syncedCourses: coursesWithoutNullUrl.length, // Replace with actual value
                    notSyncedCourses: coursesWithNullUrl.length, // Replace with actual value
                }));

            } else if (syncWithSrdsCourses.status === 207) {
                toast.error(syncWithSrdsCourses.error)
                setGetCourses(syncWithSrdsCourses.data)
                setCourses(syncWithSrdsCourses.data)
                setSearchedCourses(syncWithSrdsCourses.data)
                const coursesWithoutNullUrl = syncWithSrdsCourses.data.filter(course => course.course_url !== null);
                const coursesWithNullUrl = syncWithSrdsCourses.data.filter(course => course.course_url === null);

                setCoursesLength((prevCoursesLength) => ({
                    ...prevCoursesLength,
                    allCourses: syncWithSrdsCourses.data.length, // Replace with actual value
                    syncedCourses: coursesWithoutNullUrl.length, // Replace with actual value
                    notSyncedCourses: coursesWithNullUrl.length, // Replace with actual value
                }));

            }
            dispatch(resetSyncSchoology())
        }

        if (syncWithSrdsCourses.error === 'User not found on Schoology. Unable to sync course.') {
            toast.error(syncWithSrdsCourses.error)
        }
    }, [syncWithSrdsCourses])



    // get course list data from syncWithSrdatabase

    useEffect(() => {
        if (syncWithSrdsDatabase.data) {
            setGetCourses(syncWithSrdsDatabase.data)
            setCourses(syncWithSrdsDatabase.data)
            setSearchedCourses(syncWithSrdsDatabase.data)
            const coursesWithoutNullUrl = syncWithSrdsDatabase.data.filter(course => course.course_url !== null);
            const coursesWithNullUrl = syncWithSrdsDatabase.data.filter(course => course.course_url === null);

            setCoursesLength((prevCoursesLength) => ({
                ...prevCoursesLength,
                allCourses: syncWithSrdsDatabase.data.length, // Replace with actual value
                syncedCourses: coursesWithoutNullUrl.length, // Replace with actual value
                notSyncedCourses: coursesWithNullUrl.length, // Replace with actual value
            }));

        }
    }, [syncWithSrdsDatabase])



    // here i convert UTC date and time into user local time

    const convertToIST = (isoDate) => {
        const date = new Date(isoDate);

        // Format the date and time for the Asia/Calcutta timezone
        const formatter = new Intl.DateTimeFormat("en-IN", {
            timeZone: "Asia/Calcutta",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            // second: "2-digit",
        });

        return formatter.format(date);
    };



    // here i convert UTC date and time into user local time

    const checkDate = (formattedDate) => {
        if (!formattedDate) return
        // Parse the input date string into a Date 
        const cleanedDateStr = formattedDate
            .replace(" at ", " ") // Remove 'at' from the string
            .replace(/(\d{1,2})(st|nd|rd|th)/, "$1");

        const inputDate = new Date(cleanedDateStr);
        const now = new Date();

        // Get today's and yesterday's start of the day
        const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const startOfYesterday = new Date(startOfToday);
        startOfYesterday.setDate(startOfToday.getDate() - 1);

        // Format the time part from the input date
        const timeFormatter = new Intl.DateTimeFormat("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
            // second: "2-digit",
            hour12: true,
        });
        const formattedTime = timeFormatter.format(inputDate);

        // Check if the date is today, yesterday, or older
        if (inputDate >= startOfToday) {
            return `today at ${formattedTime}`;
        } else if (inputDate >= startOfYesterday && inputDate < startOfToday) {
            return `yesterday at ${formattedTime}`;
        } else {
            // Return the full date for older dates
            const fullDateFormatter = new Intl.DateTimeFormat("en-IN", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                // second: "2-digit",
                hour12: true,
            });
            return fullDateFormatter.format(inputDate);
        }
    };



    // if user refresh then give e popUp

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (startSync) {
                event.preventDefault();
                event.returnValue = '';  // This is required for most browsers to show the confirmation dialog.
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [startSync]);



    // Handlers for confime popUp

    const handleShow = () => {
        setOpenDialog(false);
        setStartSync(true)
        startSyncRef.current = true;
        setTimeout(() => {
            const updatedCourses = selectedCoursesId.filter(id => id !== selectedCourses[currentIndex].section_id);
            setSelectedCoursesId(updatedCourses);
            selectedCoursesIdRef.current = updatedCourses
            dispatch(importSchoologyCourseSync({ "section_ids": [selectedCourses[currentIndex].section_id], 'batch_id': uuid }, token))
        }, 3000); // Delay API call by 3 seconds
    };



    // Handlers for close popUp

    const handleClose = () => {
        setOpenDialog(false);
    };



    // handle checkBox changes

    const handleCheckboxChange = (courseId, type) => {
        if (type === 'courseDetail') {
            const getCourse = selectedCourses ? (selectedCourses.length > 0 ? selectedCourses.find(course => course.import_details[0].section_id === courseId.import_details[0].section_id) : undefined) : undefined;

            if (getCourse) {
                setSelectedCourses((prevSelected) =>
                    prevSelected.filter((course) => course.import_details[0].section_id !== getCourse.import_details[0].section_id)
                )
            } else {
                setSelectedCourses((prevSelected) => [...prevSelected, courseId])
            }

        } else {
            const getCourse = selectedCourses ? (selectedCourses.length > 0 ? selectedCourses.find(course => course.section_id === courseId.section_id) : undefined) : undefined;
            if (getCourse) {
                setSelectedCourses((prevSelected) =>
                    prevSelected.filter((course) => course.section_id !== getCourse.section_id)
                )
            } else {
                setSelectedCourses((prevSelected) => [...prevSelected, courseId])
            }
        }
    };



    // navigate user to schoology courses 

    const navigetToSchoology = (schoology_url) => {
        window.open(schoology_url, "_blank")
    }



    // handle checkBox select all courses

    const handleAllSelectCoursesDetail = (e) => {

        if (e.target.checked) {
            setSelectedCourses(schoologyDetailLogs.data)
        } else {
            setSelectedCourses([])
        }
    }

    const handleAllSelectCourses = (e) => {

        if (e.target.checked) {

            const notPrecentCourse = selectedCourses.filter(selectedCourse =>
                !courses.some(course => course.section_id === selectedCourse.section_id)
            );

            setSelectedCourses([...courses, ...notPrecentCourse])

        } else {
            setSelectedCourses((prev) =>
                prev.filter(course => !courses.some(c => c.section_id === course.section_id))
            );

        }
    }



    // handle Search cours

    const handleSearch = (e) => {
        setsearch(e.target.value)
        // if (syncCoursePage) {
        //     const getCourseName = selectedCourses.filter(course => course.name.toLowerCase().includes(e.target.value.toLowerCase()))
        //     setCourses(getCourseName)
        // } else {

        const getCourseName = searchedCourses.filter(course => course.schoology_course_name.toLowerCase().includes(e.target.value.toLowerCase()))

        setCourses(getCourseName)
        // }
    }



    // handle next button 

    const handleNext = (type) => {
        setSyncCoursePage(true);
        if (type === 'courseDetail') {
            setCourses(selectedCourses)
            setSummeryPopup(false);
        } else {
            setCourses(selectedCourses)
        }
    }



    // handle refresh courses button

    const handleRefresh = () => {
        dispatch(resetSyncSchoologydatabase())
        dispatch(syncWithSrds(token))

        // Reset the scroll position of the container to the top
        if (coursesContainerRef.current) {
            coursesContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }



    // define progress bar

    const BorderLinearProgress = styled(LinearProgress)(({ theme, progressColor }) => ({
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[200],  // Background color of the progress bar track
        '& .MuiLinearProgress-bar': {
            borderRadius: 5,
            backgroundColor: progressColor,  // Default to '#1a90ff' if no custom color is passed
        },
    }));



    // handle Ready to Sync button

    const handleSyncFun = () => {
        setOpenDialog(true);
    }



    // Function to open the popup

    const openSummaryPopup = (batch_id) => {
        setSelectedCourses([])
        dispatch(schoologyDetailLogsData(batch_id, token))
        setSummeryPopup(true);
    };



    // Function to close the popup

    const closeSummaryPopup = () => {
        setSummeryPopup(false);
        setSelectedCourses([])
        dispatch(resetschoologyDetailLogs())
    };



    // here i handle Types button like all courses, synced courses or not syned course

    const handleCourcesType = (type) => {

        if (type === 'All Courses') {
            setCourses(getCourses)
            setSearchedCourses(getCourses)
            setsearch('')
            setActiveTab(type);
        } else if (type === 'Synced Courses') {
            const coursesWithNullUrl = getCourses.filter(course => course.course_url !== null);
            setCourses(coursesWithNullUrl)
            setSearchedCourses(coursesWithNullUrl)
            setsearch('')
            setActiveTab(type);
        } else if (type === 'Not Synced courses') {
            const coursesWithNullUrl = getCourses.filter(course => course.course_url === null);
            setCourses(coursesWithNullUrl)
            setSearchedCourses(coursesWithNullUrl)
            setsearch('')
            setActiveTab(type);
        }
    }



    // handle back button

    const handleBackBTN = () => {
        if (startSync) return;
        if (syncCoursePage) {
            setSyncCoursePage(false)
            setCourses(getCourses)
            setActiveTab('All Courses')
        } else {
            navigate(-1)
        }
    }



    return (
        <div className="sync-with-schoology-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="sync-with-schoology-wrapper">
                            <div >
                                <div className='Back_button' onClick={() => handleBackBTN()} style={{ cursor: startSync ? 'not-allowed' : 'pointer', display: "flex", alignItems: "center", color: startSync ? 'gray' : '#0068FF', cursor: "pointer", marginBottom: "26px" }} >
                                    <i class="fa-solid fa-chevron-left"></i>
                                    <span style={{ padding: " 0px 5px", cursor: startSync ? 'not-allowed' : 'pointer' }}>
                                        {
                                            syncCoursePage ?
                                                'Back'
                                                : 'Dashboard'
                                        }
                                    </span>
                                </div>
                            </div>
                            {/* <BackButton buttonName={"Sync with Schoology"} /> */}
                            <div className="connected-to-schoology-profile-wrapper">
                                <div className="connected-to-schoology-profile">
                                    <div className="connected-to-schoology-profile-image">
                                        <img src={schoology_profile} alt="schoology-profile" />
                                    </div>
                                    <div className="connected-to-schoology-profile-details">
                                        <h3>{teacher_details.name}</h3>
                                        <p>{teacher_details.email}</p>
                                    </div>
                                </div>
                                <div className="connected-to-schoology-sync-wrapper">
                                    <h2>Connected to Schoology</h2>
                                    {
                                        (getSchoologyRecentSync.status === 200 && getSchoologyRecentSync.data.length > 0) &&
                                        <p> Last synced:  {checkDate(convertToIST(getSchoologyRecentSync.data[0].latest_timestamp))} </p>
                                    }
                                </div>
                            </div>
                            <div className="sync-with-schoology-list-container-wrapper" style={{ display: mobileUser && startSync ? 'none' : 'flex' }}>
                                <div className="sync-with-schoology-list-container">
                                    <div className="sync-with-schoology-list-select-courses-wrapper">
                                        {
                                            syncCoursePage ?
                                                <div className="sync-with-schoology-list-select-courses">
                                                    <h2>Sync Summary</h2>
                                                    <p>Review the courses you want to sync with our SRDS.</p>
                                                </div> :
                                                <div className="sync-with-schoology-list-select-courses">
                                                    <h2>Select Courses</h2>
                                                    <p>Choose the courses you want to sync from your Schoology account. You can select multiple courses or use the 'Select All' option to sync all available courses.</p>
                                                </div>

                                        }

                                        {
                                            !syncWithSrdsDatabase.isLoading && !syncWithSrdsCourses.isLoading && !syncCoursePage &&
                                            <div className="sync-with-schoology-list-select-courses-search">
                                                <input type="text" placeholder="Search Courses" value={search} onChange={(e) => handleSearch(e)} />
                                                <i class="fa-solid fa-magnifying-glass"></i>
                                            </div>
                                        }
                                    </div>

                                    <div className="sync-with-schoology-tab-section">
                                        <div className="sync-with-schoology-tab-wrapper">
                                            {
                                                !syncCoursePage &&
                                                <div className="view-lessons-btn-container teacher-tab-section">
                                                    <button
                                                        className={`tab-button ${activeTab === "All Courses" ? "active" : ""}`}
                                                        onClick={() => handleCourcesType("All Courses")}
                                                    >
                                                        {`All Courses (${coursesLength.allCourses})`}
                                                    </button>
                                                    <button
                                                        className={`tab-button ${activeTab === "Synced Courses" ? "active" : ""}`}
                                                        onClick={() => handleCourcesType("Synced Courses")}
                                                    >
                                                        {`Synced Courses (${coursesLength.syncedCourses})`}
                                                    </button>
                                                    <button
                                                        className={`tab-button ${activeTab === "Not Synced courses" ? "active" : ""}`}
                                                        onClick={() => handleCourcesType("Not Synced courses")}
                                                    >
                                                        {` Not Synced courses (${coursesLength.notSyncedCourses})`}
                                                    </button>
                                                </div>
                                            }
                                            <div className="tab-content">
                                                {
                                                    // (!syncCoursePage && (syncWithSrdsDatabase.data && !syncWithSrdsDatabase.isLoading) || (syncWithSrdsCourses.data && !syncWithSrdsCourses.isLoading)) && courses.length > 0 &&
                                                    (!syncCoursePage && !syncWithSrdsDatabase.isLoading && !syncWithSrdsCourses.isLoading && courses.length > 0) &&
                                                    <div className="sync-with-schoology-list-selected">
                                                        <input
                                                            type="checkbox"
                                                            id="select-all"
                                                            name="select-all"
                                                            value="Select All"
                                                            onChange={(e) => handleAllSelectCourses(e, 'course')}
                                                            style={{ cursor: "pointer" }}
                                                            checked={!summeryPopup && (courses.every(course => selectedCourses.some(selectedCourse => selectedCourse.section_id === course.section_id)))}
                                                        // checked={(getCourses && getCourses.length) === (selectedCourses && selectedCourses.length)}
                                                        />
                                                        <label htmlFor="select-all">Select All</label>
                                                    </div>
                                                }
                                                <div className="sync-with-schoology-list-courses-container" ref={coursesContainerRef}>
                                                    {
                                                        syncWithSrdsDatabase.isLoading || syncWithSrdsCourses.isLoading ?
                                                            <LoadingComponent /> :
                                                            (syncWithSrdsDatabase.error === "No courses found for this teacher." || syncWithSrdsCourses.error === "User not found on Schoology. Unable to sync course.") ?
                                                                <span>  {syncWithSrdsDatabase.error ? syncWithSrdsDatabase.error : syncWithSrdsCourses.error} </span> :
                                                                // (syncWithSrdsDatabase.data && getCourses) && getCourses.map((course) => (
                                                                courses.length === 0 ? <span>Course not found </span> : courses.map((fetchcourse) => (
                                                                    <div
                                                                        className="sync-with-schoology-list-courses-folder"
                                                                        key={fetchcourse.section_id}
                                                                    >
                                                                        <input type="checkbox"
                                                                            style={{ cursor: "pointer" }}
                                                                            disabled={syncCoursePage}
                                                                            checked={!summeryPopup && selectedCourses ? selectedCourses.some((course) => course.section_id === fetchcourse.section_id) : false}
                                                                            onChange={() => handleCheckboxChange(fetchcourse, 'course')}
                                                                        />
                                                                        <div className="sync-with-schoology-list-courses-folder-details">
                                                                            <div className="sync-with-schoology-list-courses-folder-details-image">
                                                                                <h3>{fetchcourse.schoology_course_name}</h3>
                                                                                {
                                                                                    fetchcourse.course_url === null ?
                                                                                        <span className="Synced-status-Failed">Not Synced</span> :
                                                                                        <span className="Synced-status-Success">Synced</span>
                                                                                }
                                                                            </div>
                                                                            <p onClick={() => navigetToSchoology(fetchcourse.schoology_url)}><i class="fa-solid fa-arrow-up-right-from-square"></i> Schoology</p>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                {
                                    syncCoursePage ?
                                        <div className="ready-to-sync-progress-container">
                                            <span>Ready to Sync?</span>
                                            <p>Make sure you've selected the correct courses and sync options.</p>
                                            <button type="button" className="start-sync"
                                                onClick={() => handleSyncFun()}
                                                disabled={startSync}
                                                style={{ cursor: startSync ? 'not-allowed' : 'pointer', backgroundColor: startSync ? 'gray' : '#0068FF' }}
                                            >
                                                <i class="fa-solid fa-rotate-right"></i>Start Sync
                                            </button>
                                            <p>Syncing may take a few minutes depending on the number of courses and data.</p>
                                            <div className="sync-progress-main-container">
                                                <div className="sync-progress-container">
                                                    <span>Sync Progress</span>
                                                    <div className="sync-progress-bar-folder">
                                                        {selectedCourses.map((course, index) => (

                                                            < div key={index} className="sync-progress-bar" >
                                                                <h5>{course.schoology_course_name}</h5>
                                                                < div className="sync-progress-bar-fill" >
                                                                    <BorderLinearProgress
                                                                        variant="determinate"
                                                                        value={progress[index] === 404 ? 100 : progress[index]}
                                                                        progressColor={progress[index] === 404 ? '#FF5733' : "#1a90ff"}
                                                                    />
                                                                </div>
                                                                <div className="sync-progress-bar-fill-count">
                                                                    <p>Syncing course</p>
                                                                    <span className="count" style={{ color: progress[index] === 404 && 'red' }}> {progress[index] === 404 ? 'Failed' : `${Math.floor(progress[index])}%`}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="sync-with-schoology-list-recent-sync-container">
                                            <span>Recent Sync</span>
                                            <div className="sync-with-schoology-list-recent-sync-folder-container">
                                                {
                                                    getSchoologyRecentSync.isLoading ?
                                                        <LoadingComponent /> :
                                                        (getSchoologyRecentSync.data && getSchoologyRecentSync.data.length === 0 ?
                                                            <h6>No recent syncs found</h6> :
                                                            getSchoologyRecentSync.data && getSchoologyRecentSync.data.map((syncCourses) => {
                                                                return <div
                                                                    className="sync-with-schoology-list-recent-sync-folder"
                                                                    key={syncCourses.id}
                                                                >
                                                                    <div className="sync-with-schoology-list-recent-sync-date-with-course">
                                                                        <div className="recent-sync-view-more-popup">
                                                                            <h5>
                                                                                {`${checkDate(convertToIST(syncCourses.latest_timestamp))}`}
                                                                            </h5>
                                                                            <button type="button" onClick={() => openSummaryPopup(syncCourses.batch_id)}>View More</button>
                                                                        </div>
                                                                        <div className="sync-with-schoology-list-recent-sync-date-Success-or-faild-folder">
                                                                            {
                                                                                syncCourses.success_count > 0 &&
                                                                                <div className="sync-with-schoology-list-recent-sync-date-Success-or-faild">
                                                                                    <p>{`${syncCourses.success_count} Courses`}</p>
                                                                                    <span className="recent-sync-success-status">Synced</span>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                syncCourses.failed_count > 0 &&
                                                                                <div className="sync-with-schoology-list-recent-sync-date-Success-or-faild">
                                                                                    <p>{`${syncCourses.failed_count} Courses`}</p>
                                                                                    <span className="recent-sync-failed-success">Sync Error</span>
                                                                                </div>
                                                                            }{
                                                                                syncCourses.pending_count > 0 &&
                                                                                <div className="sync-with-schoology-list-recent-sync-date-Success-or-faild">
                                                                                    <p>{`${syncCourses.pending_count} Courses`}</p>
                                                                                    <span className="recent-sync-pending-status">Pending</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        )
                                                }
                                            </div>
                                        </div>

                                }
                            </div>

                            <div className="mobile-view-progress-container" style={{ display: mobileUser && startSync ? 'block' : 'none' }}>
                                <div className="sync-progress-container">
                                    <span>View Progress</span>
                                    <p>Syncing may take a few minutes depending on the number of courses and data.</p>
                                    <div className="sync-progress-bar-folder">
                                        {selectedCourses.map((course, index) => (

                                            < div key={index} className="sync-progress-bar" >
                                                <h5>{course.schoology_course_name}</h5>
                                                < div className="sync-progress-bar-fill" >
                                                    <BorderLinearProgress
                                                        variant="determinate"
                                                        value={progress[index] === 404 ? 100 : progress[index]}
                                                        progressColor={progress[index] === 404 ? '#FF5733' : "#1a90ff"}
                                                    />
                                                </div>
                                                <div className="sync-progress-bar-fill-count">
                                                    <p>Syncing course and student enrollments</p>
                                                    <span className="count"> {progress[index] === 404 ? 'Failed' : `${Math.floor(progress[index])}%`}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {
                                !syncCoursePage && <div className="sync-with-schoology-btn-folder">
                                    <button type="button" className="refresh-course" onClick={handleRefresh}> <i class="fa-solid fa-rotate-right"></i> Refresh Course</button>
                                    <button
                                        type="button"
                                        className="Next"
                                        disabled={(!summeryPopup && selectedCourses && selectedCourses.length > 0) ? false : true}
                                        onClick={() => handleNext('Course')}
                                        style={{ cursor: (!summeryPopup && selectedCourses && selectedCourses.length > 0) ? 'pointer' : 'not-allowed', backgroundColor: (!summeryPopup && selectedCourses && selectedCourses.length > 0) ? '#0068FF' : 'gray' }}
                                    >
                                        Next
                                    </button>
                                </div>
                            }

                            {openDialog && (
                                <span className="sync-with-schoology-modal-folder">
                                    <ConformatioPopup
                                        title=" Are you sure you want to syncing the course?"
                                        No="Cancel"
                                        Yes="Confirm Sync"
                                        show={handleShow} // Pass handler for "Yes"
                                        close={handleClose} // Pass handler for "No"
                                    />
                                </span>
                            )}



                            {
                                congratulationsPopUp && <CongratulationsPopup
                                    check_image={check_image}
                                    // des={errorCount === 0 ? "Your courses are synced" : `${errorCount}/${selectedCourses.length} course synced`}
                                    des={errorCount === 0 ? "Your courses are synced" : `${selectedCourses.length - errorCount}/${selectedCourses.length} course synced`}
                                />
                            }

                            {
                                summeryPopup && (
                                    <div className="sync-status-Summary-popup-section">
                                        <div className="sync-status-Summary-popup-wrapper">
                                            <div className="sync-status-Summary-popup">
                                                {
                                                    schoologyDetailLogs.data === null ?
                                                        <LoadingComponent />
                                                        :
                                                        <>
                                                            <h2>Sync Status Summary <span> {checkDate(convertToIST(schoologyDetailLogs.data[0].sync_time))}</span></h2>
                                                            <p>Course Name</p>
                                                            <div className="sync-with-schoology-list-selected">
                                                                <input
                                                                    type="checkbox"
                                                                    id="select-all"
                                                                    name="select-all"
                                                                    value="Select All"
                                                                    onChange={(e) => handleAllSelectCoursesDetail(e, 'courseDetail')}
                                                                    style={{ cursor: "pointer" }}
                                                                    checked={(schoologyDetailLogs.data.every(course => selectedCourses.some(selectedCourse => selectedCourse.section_id === course.section_id)))}
                                                                // checked={(getCourses && getCourses.length) === (selectedCourses && selectedCourses.length)}
                                                                />
                                                                <label >Select All</label>
                                                            </div>

                                                            <div className="sync-status-Summary-list-courses-folder-container">
                                                                {schoologyDetailLogs.data.map((course) => (
                                                                    <div key={course.id} className="sync-status-Summary-list-courses-folder">
                                                                        <input
                                                                            type="checkbox"
                                                                            style={{ cursor: "pointer" }}
                                                                            // checked={summeryPopup && selectedCourses.length > 0 ? selectedCourses.some((courses) => courses.import_details[0].section_id == course.import_details[0].section_id) : false}

                                                                            checked={
                                                                                summeryPopup &&
                                                                                selectedCourses.length > 0 &&
                                                                                Array.isArray(course.import_details) &&
                                                                                course.import_details.length > 0 &&
                                                                                selectedCourses.some(
                                                                                    (courses) =>
                                                                                        Array.isArray(courses.import_details) &&
                                                                                        courses.import_details.length > 0 &&
                                                                                        courses.import_details[0].section_id == course.import_details[0].section_id
                                                                                )
                                                                            }


                                                                            // disabled={syncCoursePage}
                                                                            // checked={selectedCourses ? selectedCourses.some((c) => c.id === course.id) : false}
                                                                            onChange={() => handleCheckboxChange(course, 'courseDetail')}
                                                                        />
                                                                        <div className="sync-status-Summary-list-courses-folder-details">
                                                                            <div className="sync-status-Summary-list-courses-main-container">
                                                                                <div className="sync-status-Summary-list-courses-folder-details-container">
                                                                                    <h3>{course.schoology_course_name}</h3>
                                                                                    {course.is_successful ? (
                                                                                        <span className="Synced-status-Success">Synced</span>
                                                                                    ) : (
                                                                                        <span className="Synced-status-Failed">
                                                                                            {
                                                                                                course.import_details[0].failed_student_enrollments ? 'Error' : 'Not Synced'
                                                                                            }
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                <div className="sync-status-Summary-totle-folder">
                                                                                    <p> {`Students Enrolled ${course.import_details[0].students_enrolled}`}</p>
                                                                                    <p>{`Failed Syncs ${course.import_details[0].failed_enrollments}`}</p>
                                                                                    <p className="SchoologyURL-link" onClick={() => window.open(course.schoology_url, "_blank")}>
                                                                                        <i className="fa-solid fa-arrow-up-right-from-square"></i> Schoology
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !course.is_successful &&
                                                                                <div className="sync-status-Summary-sync-errors-detected">
                                                                                    <h2><img src={warning_syncwithschoology_icon} />Sync Errors Detected</h2>
                                                                                    <h6 style={{ color: '#991B1B' }}> {course.import_details[0].message}  </h6>
                                                                                    {
                                                                                        course.import_details[0].failed_student_enrollments && <>
                                                                                            <p style={{ color: '#991B1B' }} >Enrollment failed student</p>
                                                                                            {
                                                                                                course.import_details[0].failed_student_enrollments.map((student) => {
                                                                                                    return (
                                                                                                        <ul>
                                                                                                            <li>{student.student_name}</li>
                                                                                                            <p style={{ color: '#991B1B' }}>- {student.error_message}</p>
                                                                                                        </ul>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="sync-status-Summary-popup-sync-now"
                                                                disabled={(summeryPopup && selectedCourses && selectedCourses.length > 0) ? false : true}
                                                                style={{ cursor: (summeryPopup && selectedCourses && selectedCourses.length > 0) ? 'pointer' : 'not-allowed', backgroundColor: (summeryPopup && selectedCourses && selectedCourses.length > 0) ? '#0068FF' : 'gray' }}
                                                                onClick={() => handleNext('courseDetail')}
                                                            >Sync Now</button>
                                                            <span className="close-btn" onClick={closeSummaryPopup}><i class="fa-solid fa-x"></i></span>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}


export default SyncwithSchoology;