import { Dispatch } from "redux";
import api from "../../api/api";
import { setSyncWithSrdsdatabase, setloading, seterror, resetSyncSchoologydatabase } from "../reducers/syncWithSrdsDatabaseReducer";

const syncWithSrdatabase = (token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .get(
                `/courses/retrieve-schoology-courses/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            );

        if (result) {

            dispatch(setloading(false));
            if (result.data?.status === 200) {

                dispatch(setSyncWithSrdsdatabase(result.data.sections));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message ?? "Something Went Wrong!"));
    }
}


export { syncWithSrdatabase, resetSyncSchoologydatabase };

