import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ImportSchoologyCourseStart {
    data: string | null;
    status: number | null,
    isLoading: boolean;
    error: string | null;
}

// importSchoologyCourseReducer
const initialState: ImportSchoologyCourseStart = {
    data: null,
    status: null,
    isLoading: false,
    error: null,
};


const importSchoologyCourseSlice = createSlice({
    name: "importSchoologyCourse",
    initialState,
    reducers: {
        setImportSchoologyCourse: (state, action: PayloadAction<{ data: string | any; status: number }>) => {
            state.data = action.payload.data;
            state.status = action.payload.status;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetImportSchoologyCourse: () => initialState,
    }
})


export const { setImportSchoologyCourse, setloading, seterror, resetImportSchoologyCourse } = importSchoologyCourseSlice.actions;
export default importSchoologyCourseSlice.reducer;