import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SyncWithSrdsStart {
    data: string | null;
    status: number | null,
    isLoading: boolean;
    error: string | null;
}


const initialState: SyncWithSrdsStart = {
    data: null,
    status: null,
    isLoading: false,
    error: null,
};

const syncWithSrdsSlice = createSlice({
    name: "syncWithSrds",
    initialState,
    reducers: {
        setSyncWithSrds: (state, action: PayloadAction<{ sections: string; status: number }>) => {
            state.data = action.payload.sections;
            state.status = action.payload.status;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetSyncSchoology: () => initialState,
    }
})


export const { setSyncWithSrds, setloading, seterror, resetSyncSchoology } = syncWithSrdsSlice.actions;
export default syncWithSrdsSlice.reducer;