import api from "../api/api";

const getUserId = async (token) => {
  if (token) {
    try {
      const response = await api.get(`auth/get-user-id/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        return response?.data?.user_id;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }
};

export { getUserId };
