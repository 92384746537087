import React from "react";
import GoogleDriveUpload from "./GoogleDriveUpload";
import "./GoogleDriveUpload.css";
import UploadFromDevice from "./UploadFromDevice";
import { useAWSS3FileUpload } from "../AWSFileUpload/AWSFilesUpload";

const FileUpload = ({
    showUploadPop,
  selectedFiles,
  setSelectedFiles,
  setuploadedFileLink,
  setShowUploadPop,
  fileInputRef,
}) => {

    const { handleFileChange } = useAWSS3FileUpload({
        selectedFiles,
        setSelectedFiles,
        setuploadedFileLink,
        setShowUploadPop,
      });

  return ( <>
    {showUploadPop && 
    <div className="google-drive-popup-wrapper">
      <div className="google-drive-popup-container">
        {/* <GoogleDriveUpload
          setShowUploadPop={setShowUploadPop}
          handleFileChange={handleFileChange}
        /> */}
        <UploadFromDevice
          setShowUploadPop={setShowUploadPop}
          handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
        />
      </div>
    </div>
    }
    </>
  );
};

export default FileUpload;
