import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import api, { shareUrl } from "../../../../../../api/api";
import {
  Course_created_successfully_image,
  StudentViewCourseDetails_assignments_icon,
  course_bg_image,
  course_demo_image,
  social_image,
} from "../../../../../../assets/images/images";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../../state/global/slice";
import { AssignmentType } from "../../../../../../utils/consts";
import { changeScrollPopup } from "../../../../../../utils/utlis";
import DescriptionCoursePopup from "../../teacher/course/DescriptionCoursePopup";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";
import { Ring } from "@uiball/loaders";

const StudentViewCourseDetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelstatus, setCancelstatus] = useState(false);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const globalState = useSelector((state) => state.globalState);
  const isStudent = globalState.role === "Student";
  const isFromInvite =
    location?.state?.isFromInvite ?? props?.isFromInvite ?? true;
  const [course, setCourse] = useState(
    location?.state?.course ?? props?.course ?? {}
  ); // default to Student if no role is passed
  const [showreuestsuccesfully, setshowreuestsuccesfully] = useState(false);

  const [getcoursestatus, setCoursestatus] = useState(course?.status ?? "");

  useEffect(() => {
    fetchCourse(id);
  }, [id]);
  const getDecryptCourseId = async (encrypted_id) => {
    const decrypted_id = await api
      .get(`courses/course-invite/?id=${encrypted_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => res?.data?.course_id)
      .catch((err) => {
        // console.log(err);
        return null;
      });
    if (!decrypted_id) {
      // console.log("Error in decrypting course id");
      return;
    }
    await fetchCourse(decrypted_id);
  };
  const fetchCourse = async (id) => {
    if (token) {
      const getCourse = await api
        .get(`courses/get-course/${id}/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => res?.data?.data)
        .catch((err) => {
          // console.log(err);
          return null;
        });
      if (!getCourse) {
        // console.log("Error in getting course");
        return;
      }
      setCourse(getCourse);
      setCoursestatus(getCourse?.status ?? "");
    }
  };
  const [lessons, setlessondetails] = useState(null);
  useEffect(() => {
    if (
      course?.course_id &&
      course?.course_id !== null &&
      course?.course_id !== "" &&
      token
    ) {
      api
        .get(`lessons/get-all-lessons/course/${course?.course_id}/`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`, // `Token ${token}`
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setlessondetails(response?.data?.data);
          }
        })
        .catch((error) => {
          //
        });
    }
  }, [course?.course_id]);
  const [selLession, setSelLession] = useState(-1);

  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  };
  const courseRequested = (id) => {
    if (course?.source && course?.source === "Requested") {
      toast.error("You have already requested enrollment for this course");
    } else {
      const formData = new FormData();
      formData.append("course", id);
      const currentDate = new Date();

      const formattedDate = currentDate.toISOString().slice(0, 10);

      formData.append("enrolled_on", formattedDate);
      const url = "courses/student/enroll-course/";
      const headers = {
        Authorization: `Token ${token}`,
      };
      setIsLoading(true); // Set loading state to true
      api
        .post(url, formData, { headers })
        .then((response) => {
          if (response.data.status === 200) {
            setshowreuestsuccesfully(true);
            setTimeout(() => {
              setshowreuestsuccesfully(false);
              api
                .get("/courses/student/my-courses-dashboard/", {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token ${token}`,
                  },
                })
                .then((response) => {
                  if (response.status === 200) {
                    updateState("student_courses", response?.data?.data ?? []);
                    setIsEnrolled(true);
                    setCoursestatus("Pending");
                  }
                })
                .catch((error) => {
                  //
                })
                .finally(() => {
                  setIsLoading(false); // Set loading state to false
                });
            }, 2000);
          }
        })
        .catch((error) => { });
    }
  };
  const getShareLink = ({ platform, course, baseURL }) => {
    let shareUrl = "";
    baseURL = `${baseURL}`; // Append /view to the baseURL

    const courseDescription =
      course?.description?.length > 100
        ? `${course?.description?.substring(0, 100)}...`
        : course?.description;
    const data = `Hi! You're invited to enroll in the new course, ${course?.name} by ${course?.title} ${course?.teacher}. Check it out here:`;

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          baseURL
        )}&quote=${encodeURIComponent(data)}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          data
        )} ${encodeURIComponent(baseURL)}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          data
        )} ${encodeURIComponent(baseURL)}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(
          baseURL
        )}`;
        break;
      case "share":
        shareUrl = baseURL;
        break;
      default:
        shareUrl = baseURL;
        return;
    }

    return shareUrl;
  };
  const getPlatform = {
    FACEBOOK: "facebook",
    WHATSAPP: "whatsapp",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
    SHARE: "share",
  };
  const [sharelink, setSharelink] = useState("");

  const [showDropdown, setShowDropdown] = useState(false); // State to control visibility

  const [loading, setLoading] = useState(false);
  const toggleDropdown = () => {
    onShare(getPlatform.SHARE, false);
    setShowDropdown(!showDropdown); // Toggle the visibility state
    changeScrollPopup("auto", "view-course-details-share-form");
  };
  const [linkBaseUrl, setLinkBaseUrl] = useState("");
  const onShare = async (platform, isOpenNewWindow = true) => {
    setLoading(true);

    setLoading(false);
    if (isOpenNewWindow) {
      toggleDropdown();
    }

    const getURL = `${shareUrl}/course/${course?.course_id}/view`;
    const getShareableLink = getShareLink({
      platform: platform,
      course,
      baseURL: getURL,
    });
    setLinkBaseUrl(getURL);
    setSharelink(getShareableLink);
    if (isOpenNewWindow) {
      window.open(getShareableLink, "_blank");
    }
  };
  const onClickCopy = () => {
    toggleDropdown();
    try {
      // Check if the Clipboard API is available
      if (navigator.clipboard && window.isSecureContext) {
        // Use the Clipboard API to copy the selected text to the clipboard
        navigator.clipboard
          .writeText(linkBaseUrl)
          .then(() => {
            toast.success("Copied to clipboard", {
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .catch((error) => {
            toast.error("Failed to copy text", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        // Fallback method for non-SSL environments
        document.execCommand("copy");
        toast.success("Copied to clipboard", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Failed to copy text", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    onShare(getPlatform.SHARE, false);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);
  const cancelrequest = (courseid) => {
    const url = `courses/cancel-enrollment-request/${courseid}/`;
    const headers = {
      Authorization: `Token ${token}`,
    };
    api
      .delete(url, { headers })
      .then((response) => {
        if (response.data.status === 200) {
          setCancelstatus(true);
          setshowreuestsuccesfully(true);
          setTimeout(() => {
            setshowreuestsuccesfully(false);
            setOpenDialog(false);
            setCancelstatus(false);
            setCoursestatus(null);
            setIsEnrolled(false);

          }, 2000);
        }
      })
      .catch((error) => { });
  };

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const paragraphRef = useRef(null);

  const descriptionToggle = () => {
    setDescriptionOpen(!descriptionOpen);
  };

  useEffect(() => {
    const descriptionLength = course?.description?.length || 0;

    if (paragraphRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(paragraphRef.current).lineHeight
      );
      const height = paragraphRef.current.clientHeight;
      const lineCount = height / lineHeight;

      // Set the condition to show the 'View More' button for either more than 3 lines or more than 500 characters
      setShowViewMore(lineCount > 5 || descriptionLength > 1000);
    }
  }, [course?.description]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCancelClick = () => {
    setOpenDialog(true);
  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };

  return (
    <>
      <div className="dashboard" id="StudentViewCourseDetails-section">
        <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder" onClick={() =>
                  navigate("/dashboard/home", { state: { course } })
                }>
                  <i

                    class="fa-solid fa-chevron-left"
                  ></i>
                  <h2>Back</h2>
                </div>

                <div className="studentDashboar-profile-btn-folder">
                  <div className="view-course-details-edit-and-share-container">
                    <div
                      onClick={toggleDropdown}
                      className="view-course-details-edit-and-share-folder view-course-details-share-btn"
                      id="student-share-btn"
                    >
                      <span href="#">
                        <i className="fa-solid fa-share-nodes"></i>
                      </span>
                      <p>Share</p>
                    </div>
                  </div>
                  {getcoursestatus === "" ||
                    getcoursestatus == null ||
                    getcoursestatus === "Rejected" ||
                    getcoursestatus === "Visited" ? (
                    <>
                      {
                        isLoading ? (
                          <Ring />
                        ) : (
                          <button
                            className="enroll_now_btn"
                            type="button"
                            style={{
                              fontSize: 16,
                            }}
                            onClick={() => {
                              courseRequested(course?.course_id);
                            }}
                            disabled={isEnrolled || isLoading}
                          >
                            {isEnrolled ? "Request sent" : "Enroll"}
                          </button>
                        )
                      }
                    </>
                    // <button
                    //   className="enroll_now_btn"
                    //   type="button"
                    //   style={{
                    //     fontSize: 16,
                    //   }}
                    //   onClick={() => {
                    //     courseRequested(course?.course_id);
                    //   }}
                    //   disabled={isEnrolled || isLoading}
                    // >
                    //   {isEnrolled ? "Request sent" : "Enroll"}
                    //   {isLoading ? <Ring /> : isEnrolled ? "Request sent" : "Enroll"}
                    // </button>



                  ) : (
                    <button
                      className="enroll_now_btn"
                      style={{
                        fontSize: 16,
                      }}
                      type="button"
                      disabled="true"
                    >
                      {getcoursestatus === "Pending"
                        ? "Request sent"
                        : getcoursestatus}
                    </button>
                  )}
                  {getcoursestatus === "Pending" ? (
                    <button
                      className="enroll_now_btn"
                      type="button"
                      style={{
                        fontSize: 14,
                      }}
                      onClick={() => {
                        handleCancelClick();
                      }}
                    >
                      Cancel Request
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                {openDialog && (
                  <div className="Course-created-successfully-main">
                    <div className="Course-created-successfully-container">
                      <div className="Course-created-successfully-folder">
                        <h2>Are you sure you want to cancel the request?</h2>
                        <div className="Course-close-btn">
                          <button type="submit" onClick={cancelRegistration}>
                            No
                          </button>
                          <button
                            type="submit"
                            onClick={() => {
                              cancelrequest(course?.course_id);
                            }}
                            className="Course-close"
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="view-course-details-container">
                  <div className="view-course-details-folder student-view-course-Detaails-folder">
                    <div className="view-course-details-left-section">
                      <div className="view-course-details-image">
                        <img src={course?.image || course_demo_image} alt="" />
                      </div>
                    </div>
                    <div className="view-course-details-right-section">
                      <h2>{course?.name}</h2>
                      <div className="teacherviewcoursedetails-des-wrapper">
                        <p
                          className="teacherviewcoursedetails-des"
                          ref={paragraphRef}
                        >
                          {course?.description}
                          {showViewMore && (
                            <span
                              className="view-more"
                              onClick={descriptionToggle}
                            >
                              ...View More
                            </span>
                          )}
                        </p>
                        {descriptionOpen && (
                          <DescriptionCoursePopup
                            title="My Course Description"
                            data={course?.description}
                            onClick={descriptionToggle}
                          />
                        )}
                      </div>
                      <div className="view-course-details-container">
                        <div className="view-Students-course-details-folder">
                          <span>Total Lessons</span>
                          <div className="view-Students-course-details-counts">
                            <p>{course?.total_lessons}</p>
                            <p>
                              {course?.total_lessons > 1 ? "Lessons" : "Lesson"}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="view-Students-course-details-folder">
                          {
                            isStudent ? (
                              <>
                                <span>Total Assignments</span>
                                <div className="view-Students-course-details-counts">
                                  <p>{course.total_assignments}</p>
                                  <p>{course.total_assignments > 1 ? "Assignments" : "Assignment"}{" "}</p>
                                </div>
                              </>

                            ) : (
                              <>
                                <span>Total Assignments</span>
                                <div className="view-Students-course-details-counts">
                                  <p>{course.total_assignments}</p>
                                  <p>{course.total_assignments > 1 ? "Assignments" : "Assignment"}{" "}</p>
                                </div>
                                <span>Students Enrolled</span>
                                <div className="view-Students-course-details-counts">
                                  <p>{course?.enrolled_students}</p>
                                  <p>
                                    {course?.enrolled_students > 1
                                      ? "Students"
                                      : "Student"}{" "}
                                  </p>
                                </div>
                              </>
                            )
                          }

                        </div>
                        <div className="view-Students-course-details-folder">
                          <span>Course Start Date</span>
                          <div className="view-Students-course-details-counts">
                            <p>
                              {dayjs(course?.start_date).format("MMM DD, YYYY")}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="view-Students-course-details-folder">
                          <span>Course Expiry Date:</span>
                          <div className="view-Students-course-details-counts">
                            <p>
                              {course?.end_date !== null &&
                                course?.end_date !== ""
                                ? dayjs(course?.end_date).format("MMM DD, YYYY")
                                : "- -"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {lessons && (
                  <div className="StudentViewCourseDetails-lesson-wrapper">
                    <h2>Lessons</h2>
                    {lessons?.map((lesson, index) => (
                      <StudentViewCourseLesson
                        id={index}
                        selLession={index === selLession}
                        lesson={lesson}
                        onExpand={() => onExpand(index)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {showreuestsuccesfully && (
            <div className="Course-created-successfully-main">
              <div className="Course-created-successfully-container">
                <div className="Course-created-successfully-folder">
                  <div className="Course-created-successfully-image">
                    <img src={Course_created_successfully_image} alt="" />
                  </div>
                  <h2>
                    {cancelstatus
                      ? "Course request cancel successfully"
                      : "Course request sent successfully"}
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
        {showDropdown && (
          <form className="view-course-details-share-form">
            <div
              className="view-course-details-share-folder"
              data-aos="zoom-in"
            >
              <h2>Share Course</h2>
              <div className="view-course-details-inner-wrapper">
                <p>Share this link via</p>
                <div className="view-course-social-links-folder">
                  <div
                    onClick={() => onShare(getPlatform.WHATSAPP)}
                    className="view-course-social-links-image"
                  >
                    <img src={social_image} alt="" />
                  </div>
                </div>
                <p>Or copy link</p>
                <div className="copy-link-or-path-folder">
                  <input
                    type="text"
                    value={linkBaseUrl}
                    placeholder="example.com/share-link"
                    disabled
                  />
                  <button type="button" onClick={onClickCopy}>
                    Copy
                  </button>
                </div>
                <i
                  onClick={toggleDropdown}
                  className="fa-solid fa-xmark shocial-cross-btn share-course-social-media-btn"
                ></i>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

const StudentViewCourseLesson = (props) => {
  const getAssignmentTypeValue = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.name === type;
    })?.value;
  };
  const getAssignmentTypeName = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.value === type;
    })?.name;
  };
  const [assignments, setAssignments] = useState([]);
  const { token } = useAuth();
  useEffect(() => {
    if (props.selLession) {
      setAssignments([]);
      api
        .get(
          `assignments/get-assignments-by-lesson/${props.lesson?.lesson_id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`, // `Token ${token}`
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setAssignments(response?.data?.data ?? []);
          }
        })
        .catch((error) => {
          setAssignments([]);
        });
    }
  }, [props.selLession]);
  return (
    <div className="StudentViewCourseDetails-lesson-containner">
      <div className="StudentViewCourseDetails-lesson-folder">
        <div className="StudentViewCourseDetails-lesson-toggle-dropdown-section">
          <div className="StudentViewCourseDetails-lesson-toggle-btn">
            <span onClick={() => props.onExpand()}>
              {props.selLession ? (
                <i className="fa-solid fa-arrow-down"></i>
              ) : (
                <i className="fa-solid fa-arrow-right"></i>
              )}
            </span>
            <h2>Lesson 0{props?.id + 1}</h2>
          </div>
          <span className="StudentViewCourseDetails-lesson-des">
            {props.lesson?.name}
          </span>
          <div className="StudentViewCourseDetails-lesson-assignments-tab">
            <p>
              <div className="StudentViewCourseDetails-assignments0-icon">
                <img src={StudentViewCourseDetails_assignments_icon} alt="" />
              </div>
              <span className="StudentViewCourseDetails-assignments-count">
                {props?.lesson?.assignment_count && props?.lesson?.assignment_count > 0
                  ? `${props?.lesson?.assignment_count} assignment`
                  : "No assignments"}
              </span>
            </p>
          </div>
        </div>
        {props.selLession && (
          <div className="StudentViewCourseDetails-lesson-inner-assignments-container">
            <h2>{props.lesson?.name}</h2>
            <div className="StudentViewCourseDetails-lesson-inner-assignments-wrapper">
              {assignments.map((assignment, index) => (
                <div className="StudentViewCourseDetails-lesson-inner-assignments-folder">
                  <h2>
                    Assignment <span>{index > 9 ? index : '0' + (index + 1)}</span>: <span>{" "}{assignment?.name} {" "}</span>
                  </h2>
                  <h2>{" "}{getAssignmentTypeName(assignment?.type)}</h2>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentViewCourseDetails;
