import { Dispatch } from "redux";
import api from "../../api/api";
import {
  setCourseCheckPublishtoSchoology, setloading, seterror, resetCourseCheckPublishtoSchoology
} from "../reducers/courseCheckPublishtoSchoologyReducer";

const setcourseCheckPublishtoSchoologydtls = (data: any, token: any) => async (dispatch: Dispatch) => {
  dispatch(setloading(true));
  try {
    const result = await api
      .post(
        `/courses/schoology-course-checker/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
       );
       
       if(result){
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setCourseCheckPublishtoSchoology(result.data));
            } else {
                dispatch(seterror(result.data ?? "Something Went Wrong!"));
            }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data ?? "Something Went Wrong!"));
   }
}


export { setcourseCheckPublishtoSchoologydtls, resetCourseCheckPublishtoSchoology };

