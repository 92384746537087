import { Dispatch } from "redux";
import api from "../../api/api";
import { setImportSchoologyCourse, setloading, seterror, resetImportSchoologyCourse } from "../reducers/importSchoologyCourseReducer";

const importSchoologyCourseSync = (data: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api.post(`/courses/process-schoology-course/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });



        if (result) {
            dispatch(setloading(false));
            if (result.data.details.results[0].success) {
                dispatch(setImportSchoologyCourse({
                    data: result.data.details.results[0],
                    status: result.data.status,
                }));
            } else {
                dispatch(setImportSchoologyCourse({
                    data: null,
                    status: result.data.status,
                }));
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.data.details.errors[0].message ?? "Something Went Wrong!"));
    }
}


export { importSchoologyCourseSync, resetImportSchoologyCourse };

