import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SyncWithSrdsDatabaseStart {
    data: string | null;
    isLoading: boolean;
    error: string | null;
}


const initialState: SyncWithSrdsDatabaseStart = {
    data: null,
    isLoading: false,
    error: null,
};




const syncWithSrdsDatabaseSlice = createSlice({
    name: "syncWithSrdsDatabase",
    initialState,
    reducers: {
        setSyncWithSrdsdatabase: (state, action: PayloadAction<string>) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetSyncSchoologydatabase: () => initialState,
    }
})


export const { setSyncWithSrdsdatabase, setloading, seterror, resetSyncSchoologydatabase } = syncWithSrdsDatabaseSlice.actions;
export default syncWithSrdsDatabaseSlice.reducer;