import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SchoologyDetailLogsStart {
    data: string | null;
    isLoading: boolean;
    error: string | null;
}

// importSchoologyCourseReducer
const initialState: SchoologyDetailLogsStart = {
    data: null,
    isLoading: false,
    error: null,
};


const schoologyDetailLogsSlice = createSlice({
    name: "schoologyDetailLogs",
    initialState,
    reducers: {
        setSchoologyDetailLogs: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetschoologyDetailLogs: () => initialState,
    }
})


export const { setSchoologyDetailLogs, setloading, seterror, resetschoologyDetailLogs } = schoologyDetailLogsSlice.actions;
export default schoologyDetailLogsSlice.reducer;